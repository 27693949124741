import React, { Component } from 'react';
import '@styles/public/home/slideShow.scss'; 

export class SlideShow extends Component {
    static displayName = SlideShow.name;

    constructor(props) {
        super(props);

        this.state = {

            currentSlide: 0,
            slides: this.props.slideShow,

            autoPlay: this.props.slideShow.length > 1,

            slideProgress: 0,
            slideProgressStep: 10, // ms

            screenWidth: window.innerWidth
        };

        this.swipeStart = 0;
        this.minSwipeDistance = 40;
    }

    componentDidMount() {
        this.HandleSetSlideAutoPlayProgress();
        window.addEventListener('resize', this.UpdateScreenWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.UpdateScreenWidth);
    }

    UpdateScreenWidth = () => {
        this.setState((prevState) => ({ ...prevState, screenWidth: window.innerWidth }));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.slideShow != this.props.slideShow) {
            this.setState((prevState) => ({ ...prevState, slides: this.props.slideShow, currentSlide: 0 }));
        }
    } 

    HandleSetSlideAutoPlayProgress = (forcePlay = false) => {

        if (!this.state.autoPlay && !forcePlay )
            return;

        let newProgress = this.state.slideProgress + this.state.slideProgressStep;
        if (newProgress > this.state.slides[this.state.currentSlide].duration) {
            this.HandleShowNextSlide();
            newProgress = 0;
        }
            
        this.setState((prevState) => ({ ...prevState, slideProgress: newProgress }));

        setTimeout(() => {
            this.HandleSetSlideAutoPlayProgress();
        }, this.state.slideProgressStep);
    }

    HandleShowNextSlide = () => {
        let next = this.state.currentSlide + 1;
        this.HandleSetSlide(next);
    }

    HandleShowPrevSlide = () => {
        let next = this.state.currentSlide - 1;
        this.HandleSetSlide(next);
    }

    HandleSetSlide = (i) => {

        if (i < 0)
            i = this.state.slides.length - 1;
        
        if (i >= this.state.slides.length)
            i = 0;

        this.setState((prevState) => ({ ...prevState, currentSlide: i, slideProgress: 0 }));
    }

    HandleToggleAutoPlay = () => {

        let newAutoPlay = !this.state.autoPlay;

        if (newAutoPlay)
            this.HandleSetSlideAutoPlayProgress(true);

        this.setState((prevState) => ({ ...prevState, autoPlay: newAutoPlay }));
    }

    GetImageExtension = (img) => {
        let split = img.split(".");
        return split[split.length - 1];
    }

    RenderSlides = () => {

        return (
            <div className='slides w-100 h-100 d-flex overflow-hidden' ref={this.props.link?.main.ref}>
                {this.state.slides.map((slide, i) => {
                    return (
                        <div style={{ translate: (-100 * this.state.currentSlide) + '%' }} className={`slide position-relative col-12 w-100 h-100`} key={i}>

                            <figure className='w-100 h-100'>
                                <picture>
                                    {slide.imgMobile.length > 0 &&
                                        <source srcSet={`files/images/${slide.imgMobile}`} media="(min-width: 0px) and (max-width: 576px)" type={`image/${this.GetImageExtension(slide.imgMobile)}`} />
                                    }
                                    {slide.imgTablet.length > 0 &&
                                        <source srcSet={`files/images/${slide.imgTablet}`} media="(min-width: 577px) and (max-width: 1200px)" type={`image/${this.GetImageExtension(slide.imgTablet)}`} />
                                    }
                                    {slide.imgPc.length > 0 &&
                                        <source srcSet={`files/images/${slide.imgPc}`} media="(min-width: 1201px) and (max-width: 1920px)" type={`image/${this.GetImageExtension(slide.imgPc)}`} />
                                    }
                                    {slide.imgPcLarge.length > 0 &&
                                        <source srcSet={`files/images/${slide.imgPcLarge}`} media="(min-width: 1921px)" type={`image/${this.GetImageExtension(slide.imgPcLarge)}`} />
                                    }
                                    <img src={`files/images/${slide.imgPc}`} alt={
                                        this.props.isAdm ?
                                            slide.text
                                            :
                                            this.props.translateRaw(slide.text)
                                    } />
                                </picture>
                            </figure>

                            <div className='caption position-absolute pe-3' style={{ color: slide.fontColor, top: ((this.state.screenWidth <= 576 || this.props.forceMobile) ? slide.captionMobileY : slide.captionY), left: ((this.state.screenWidth <= 576 || this.props.forceMobile) ? slide.captionMobileX : slide.captionX) }}>
                                <div className='heading' style={{ color: slide.fontColor }}>
                                    {this.props.isAdm ?
                                        slide.heading
                                        :
                                        this.props.translate(slide.heading)
                                    }
                                </div>
                                <div className='text' style={{ color: slide.fontColor }}>
                                    {this.props.isAdm ?
                                        slide.text
                                        :
                                        this.props.translate(slide.text)
                                    }
                                </div>
                            </div>
                        </div>
                    );
                })} 
            </div>
        );    
    }

    RenderSlidePoints = () => {

        return (
            <div className={`position-absolute slidePoints ${this.props.forceMobile && 'forceMobile'} d-flex align-items-center p-2 p-md-3`}>            
                {this.state.slides.map((slide, i) => {

                    let isCurrent = this.state.currentSlide == i;
                    return (
                        <div key={i} className={`${isCurrent && 'current'}`} role='button' onClick={() => this.HandleSetSlide(i) }></div>                   
                    );
                })}
            </div>
        );
    }

    RenderAutoPlay = () => {
        return (
            <div className='autoPlay position-absolute bottom-0 end-0 p-2 p-md-3 d-flex align-items-center' onClick={() => this.HandleToggleAutoPlay() }>
                <progress value={this.state.slideProgress} max={this.state.slides[this.state.currentSlide].duration} className='me-2' />
                <i className={`fa-solid fa-${!this.state.autoPlay ? 'play' : 'pause'} d-flex align-items-center justify-content-center`} role='button' />
            </div>
        );
    }

    HandleDragStart = (e) => {
        this.swipeStart = e.clientX;
    }

    HandleDragEnd = (e) => {
        let drag = this.swipeStart - e.clientX;
        this.HandleSwipeDrag(drag);
    }

    HandleSwipeStart = (e) => {
        this.swipeStart = e.changedTouches[0].screenX;
    }

    HandleSwipeEnd = (e) => {
        let swipe = this.swipeStart - e.changedTouches[0].screenX;
        this.HandleSwipeDrag(swipe);
    }

    HandleSwipeDrag = (dist) => {
        if (Math.abs(dist) < this.minSwipeDistance)
            return;

        // swipe right else left
        if (dist < 0) {
            this.HandleShowPrevSlide();
        } else {
            this.HandleShowNextSlide();
        }
    }

    RenderSidePanels = () => {
        return <>
            <div role='button' className='sidePanel prev position-absolute d-flex align-items-center justify-content-center' onClick={() => this.HandleShowPrevSlide() }>
                <i className="fa-solid fa-chevron-left p-3 p-md-4 m-md-3" aria-hidden="true"></i>
            </div>
            <div role='button' className='sidePanel next position-absolute d-flex align-items-center justify-content-center' onClick={() => this.HandleShowNextSlide() }>
                <i className="fa-solid fa-chevron-right p-3 p-md-3 m-md-3" aria-hidden="true"></i>
            </div>
        </>
    }

    render() {
        return (
            this.state.slides.length > 0 &&
            <section id='slideShow'
                    className={`${this.props.forceMobile && 'forceMobile'} position-relative w-100 m-0 p-0`}
                    onTouchStart={(e) => this.HandleSwipeStart(e)}
                    onTouchEnd={(e) => this.HandleSwipeEnd(e)}
                    onMouseDown={(e) => this.HandleDragStart(e)}
                    onMouseUp={(e) => this.HandleDragEnd(e)}
                >
                    {this.RenderSlides()}
                    {(this.state.slides.length > 1 || this.props.forceShowControls) &&
                        <>
                            {this.RenderSidePanels()}
                            {this.RenderSlidePoints()}
                            {this.RenderAutoPlay()}
                        </>
                    }
                </section>
        );
    }
}
