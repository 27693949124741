import React, { Component } from 'react';
import { Button } from '@components/shared/Button';

export class FilterFiles extends Component {
    static displayName = FilterFiles.name;

    constructor(props) {
        super(props);

        this.state = {

        };

        this.paginationOptions = [
            { text: '1', value: 1},
            { text: '10', value: 10},
            { text: '25', value: 25},
            { text: '50', value: 50},
            { text: '100', value: 100},
            { text: 'Vše', value: 'all' },
        ];
    }

    HandleDeleteFilters = () => {

        if (JSON.stringify(this.props.defaultFilters) == JSON.stringify(this.props.filters))
            return;

        this.props.HandleSetFilters(this.props.defaultFilters);
    }

    HandleSetFilterName = (val) => {
        let newFilters = { ...this.props.filters, name: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterExtension = (val) => {
        let newFilters = { ...this.props.filters, extension: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterPaginationCount = (val) => {
        let newFilters = { ...this.props.filters, paginationCount: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterPaginationPage = (val) => {
        let newFilters = { ...this.props.filters, paginationPage: val }
        this.props.HandleSetFilters(newFilters);
    }

    RenderPaginationText = () => {

        let pagination = this.props.filters.paginationCount == 'all' ? this.props.fileListCount : this.props.filters.paginationCount;
        let paginationStart = (this.props.filters.paginationPage - 1) * pagination;
        return <div className=''>
            <div>
                {`Záznam ${paginationStart} - ${paginationStart + this.props.fileListCount} z celkových ${this.props.allRowsCount}`}
            </div>
            <div>
                Počet záznamů: {(paginationStart + this.props.fileListCount) - paginationStart}
            </div>
        </div>
    }

    RenderPaginationPageNumbers = () => {

        let numberOfVisiblePages = 10;

        let numberOfPages = Math.ceil(this.props.allRowsCount / this.props.filters.paginationCount);
        if (!numberOfPages || numberOfPages <= 0)
            numberOfPages = 1;

        let pageNumbers = [];
        for (let i = 1; i <= numberOfPages; i++) {

            if (i > numberOfVisiblePages && i < numberOfPages - numberOfVisiblePages) {
                if(pageNumbers[pageNumbers.length-1] != '...')
                    pageNumbers.push('...');

                continue;
            }

            pageNumbers.push(i);
        }

        return (
            <div className='d-flex align-items-center flex-wrap'>
                {pageNumbers.map((pn) => {

                    if (pn == '...')
                        return <div key={pn} className='fw-bold'>{pn}</div>

                    return (
                        <div role='button' key={pn} className={`mx-1 pageNumber ${this.props.filters.paginationPage == pn ? 'current fw-bold' : ''}`} onClick={() => this.HandleSetFilterPaginationPage(pn) }>
                            {pn}
                        </div>
                    );
                })}
            </div>
        );     
    }

    render() {
        return (
            <div className='d-flex flex-column'>
                <div className='border-bottom my-2 pb-2'>
                    <div className='d-flex flex-column flex-lg-row align-items-lg-end'>
                        <div className='d-flex flex-wrap align-items-end'>
                            <div className='mx-2 d-flex flex-column'>
                                <label htmlFor='state'>Záznamů na stránce</label>
                                <select id='state' className='p-1' value={this.props.filters.paginationCount} onChange={(e) => this.HandleSetFilterPaginationCount(e.target.value)}>
                                    {this.paginationOptions.map((o) => {
                                        return (
                                            <option className='p-1' value={o.value} key={o.value}>
                                                {o.text}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='mx-2 d-flex flex-column me-3'>
                                <label htmlFor='name'>Vyhledat název</label>
                                <input type='text' id='name' value={this.props.filters.name} onChange={(e) => this.HandleSetFilterName(e.target.value)} />
                            </div>
                            {this.props.extensionList.length > 1 &&
                                <div className='mx-2 d-flex flex-column'>
                                    <label htmlFor='extension'>Formát</label>
                                    <select id='extension' className='p-1' value={this.props.filters.extension} onChange={(e) => this.HandleSetFilterExtension(e.target.value)}>
                                        <option className='p-1' value={'all'}>
                                            Vše
                                        </option>
                                        {this.props.extensionList.map((e) => {
                                            return (
                                                <option className='p-1' value={e} key={e}>
                                                    {e}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            }
                        </div>
                        <div className='ps-1 mt-2 mt-lg-0'>
                            <Button text={'Smazat filtry'} small OnClick={() => this.HandleDeleteFilters()} />
                        </div>
                    </div>
                </div>
                <div className='pb-2 d-flex flex-column'>
                    <span className='me-1 d-flex'>Stránka:
                        {this.RenderPaginationPageNumbers()}
                    </span>
                    {this.RenderPaginationText()}
                </div>
            </div>
        );
    }
}
