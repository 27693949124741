import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import { ToolTip } from '@components/shared/ToolTip';

export class ImportExportTranslation extends Component {
    static displayName = ImportExportTranslation.name;

    constructor(props) {
        super(props);

        this.state = {

            response: null,

            loading: false,
            importErrorList: [],

            json: "",
            jsonInvalid: false,
            translationsToImportCount: 0,

            jsonOpen: true,
            errorLogOpen: false,

            overwriteExisting: false,
            truncateTable: false,

            downloadExported: true,
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleToggleOverWriteExisting = () => {
        this.HandleDeleteResponse();
        let overwrite = this.state.truncateTable ? false : !this.state.overwriteExisting;
        this.setState((prevState) => ({ ...prevState, overwriteExisting: overwrite }));
    }

    HandleToggleTruncateTable = () => {
        this.HandleDeleteResponse();
        let truncate = !this.state.truncateTable;
        this.setState((prevState) => ({ ...prevState, truncateTable: truncate, overwriteExisting: truncate ? false : this.state.overwriteExisting }));
    }

    HandleOpenJson = () => {
        this.setState((prevState) => ({ ...prevState, jsonOpen: true, errorLogOpen: false }));
    }

    HandleOpenErrorLog = () => {
        this.setState((prevState) => ({ ...prevState, jsonOpen: false, errorLogOpen: true }));
    }

    HandleSetJson = (json) => {
        this.setState((prevState) => ({ ...prevState, json: json, response: null, jsonInvalid: false, jsonEmpty: false }));
    }

    HandleDeleteResponse = () => {
        this.setState((prevState) => ({ ...prevState, response: null }));
    }

    RenderImportResponse = () => {
        switch (this.state.response) {
            case 200:
                let importErrorNum = this.state.importErrorList.length;
                let importSuccessNum = this.state.translationsToImportCount - importErrorNum;
                return <InfoMessage text={`Bylo importováno ${importSuccessNum} z ${this.state.translationsToImportCount} záznamů! (${importErrorNum} chybných)`} isSuccess stayVisible />;
            case 400:
                return <InfoMessage text={"Chyba ve struktuře zadaných dat."} stayVisible />;
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    RenderImportErrorLogResponse = (response) => {
        switch (response) {
            case 400:
                return "400 | Chyba v zadaných datech!";
            case 403:
                return "403 | Tento záznam nelze upravit!";
            case 409:
                return "409 | Záznam již existuje!";
            default:
                return;
        }
    }

    ImportTranslations = () => {

        let jsonInvalid = false;
        let jsonEmpty = false;
        let translationList;

        try {
            translationList = JSON.parse(this.state.json);
            if (!translationList)
                jsonInvalid = true;

            jsonEmpty = translationList.length <= 0;
        } catch {
            jsonInvalid = true;
        }

        this.setState((prevState) => ({ ...prevState, loading: !(jsonEmpty || jsonInvalid), jsonEmpty: jsonEmpty, jsonInvalid: jsonInvalid }));

        if (jsonEmpty || jsonInvalid)
            return;

        this.setState((prevState) => ({ ...prevState, translationsToImportCount: translationList.length }));

        axios
            .post("/api/adm/translation/import", {
                TranslationList: translationList,
                Overwrite: this.state.overwriteExisting,
                TruncateTable: this.state.truncateTable
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    response: data.responseCode,
                    importErrorList: data.errorList
                }));

                if (data.responseCode === 200)
                    this.props.GetTranslationList();
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 400 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleExport = () => {

        let exportTranslationList = [];
        for (let i in this.props.translationList) {

            let t = this.props.translationList[i];
            let newTranslation = {
                Key: t.key,
                InternalNote: t.internalNote,
                PageName: t.page.name,
                IsActive: t.isActive,
                IsCached: t.isCached,
                LocalizationList: []
            }

            for (let j in t.localizationList) {
                let localization = t.localizationList[j];
                newTranslation.LocalizationList.push({ LanguageCode: localization.language.code, Value: localization.value })
            }

            exportTranslationList.push(newTranslation);
        }

        let json = JSON.stringify(exportTranslationList, null, 3);
        this.HandleSetJson(json);
        if (this.state.downloadExported)
            this.HandleDownloadExported(json);
    }

    getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month starts from 0
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    };

    HandleDownloadExported = (json) => {
        const blob = new Blob([json], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        let fileName = `translation_dump_${this.getCurrentDate()}.json`;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Cleanup
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    HandleImportFile = (file) => {

        this.HandleDeleteResponse();
        if (!file) {
            this.setState((prevState) => ({ ...prevState, json: "", jsonInvalid: false }));
            return;
        }

        let extension = `.${file.name.split('.').pop().toLowerCase()}`;

        let jsonInvalid = '.json' != extension;
        this.setState((prevState) => ({ ...prevState, jsonInvalid: jsonInvalid, file: file }));
        if (jsonInvalid)
            return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const content = JSON.parse(e.target.result);
                let jsonPretty = JSON.stringify(content, null, 3);
                this.HandleSetJson(jsonPretty);
                console.log(content);
            } catch (error) {
                this.setState((prevState) => ({ ...prevState, json: "", jsonInvalid: true }));
            }
        };
        reader.readAsText(file);
    }

    HandleToggleDownloadExported = () => {
        this.setState((prevState) => ({ ...prevState, downloadExported: !this.state.downloadExported }));
    }

    RenderNumOfExported = () => {

        try {
            let num = JSON.parse(this.state.json)?.length;

            return <div className=''>
                <div className=''>
                    Exportováno {num} záznamů
                </div>
            </div>
        } catch {
            return <></>
        }
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH importExport'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Import/Export překladů</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='d-flex flex-column mt-3 pb-3 border-bottom'>
                        <div className='mb-2'>
                            <div className='d-flex align-items-center'>
                                <Button text={<div><i className="fa-solid fa-file-export"></i> Exportovat</div>} OnClick={() => this.HandleExport()} />
                                <div className='ms-2'>Exportovat aktuálně vyfiltrované záznamy</div>
                            </div>
                            {this.props.FiltersActive() &&
                                <div className='mt-1 mb-2 d-flex align-items-center'>
                                    <div className='redText me-1'>Pozor, máte aplikované filtry!</div>
                                    (Zkontrolujte filtr počtu záznamů na stránce)
                                </div>
                            }
                        </div>
                        <SliderOnOff text={'Stáhnout exportované záznamy'} reversed isChecked={this.state.downloadExported} OnChange={() => this.HandleToggleDownloadExported()} />
                    </div>
                    <div className='d-flex align-items-center mt-3 pb-3 border-bottom'>
                        <label htmlFor='file' className='me-2'>Importovat json</label>
                        <input type='file' className='border-0' id='file' onChange={(e) => this.HandleImportFile(e.target.files[0])} accept=".json" />
                    </div>
                    <div className={`d-flex flex-column my-3 col-12 ${this.state.jsonEmpty && 'inputError'}`}>
                        <div className='d-flex'>
                            <label role='button' htmlFor='json' className={`fw-bold p-1 px-3 pb-0 ${this.state.jsonOpen ? 'gray' : 'border border-bottom-0'}`} onClick={() => this.HandleOpenJson()}>JSON</label>
                            <label role='button' htmlFor='json' className={`d-flex align-items-center fw-bold p-1 px-3 pb-0 ${this.state.errorLogOpen ? 'gray' : 'border border-bottom-0'}`} onClick={() => this.HandleOpenErrorLog()}>
                                {this.state.importErrorList.length} Error messages
                                {this.state.importErrorList.length > 0 && <i className="ms-1 fa-solid fa-exclamation redText"></i>}
                            </label>
                        </div>
                        {this.state.jsonOpen &&
                            <div className='col-12 position-relative'>
                                <textarea type='text' id='json' className='ps-2 col-12' value={this.state.json} onChange={(e) => this.HandleSetJson(e.target.value)} />
                                <i className="fa-solid fa-xmark position-absolute deleteField" onClick={() => this.HandleImportFile(null)} />
                            </div>
                        }
                        {this.state.errorLogOpen &&
                            <div className='d-flex flex-column errorLog p-2 overflow-auto'>
                                {this.state.importErrorList.map((e, i) => {
                                    return (
                                        <div key={i} className='d-flex align-items-center border-bottom p-1'>
                                            {i + 1}. Key <b className='ms-1 me-1'> {e.key}</b> -> {this.RenderImportErrorLogResponse(e.responseCode)}
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        {this.RenderNumOfExported()}
                    </div>
                    {this.state.jsonOpen &&
                        <>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex flex-column'>
                                    <div className='mb-2 d-flex align-items-center'>
                                        <SliderOnOff text={'Přepsat existující záznamy'} isChecked={this.state.overwriteExisting} reversed OnChange={() => this.HandleToggleOverWriteExisting()} />
                                        <div className='ms-1'>
                                            <ToolTip text={'V případě importování překladu s klíčem, který již v databázi existuje, bude starý překlad přepsán.'} />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <SliderOnOff text={'Před importem smazat veškteré záznamy'} isChecked={this.state.truncateTable} reversed OnChange={() => this.HandleToggleTruncateTable()} />
                                        <div className='ms-1'>
                                            <ToolTip text={'Při validním pokusu o import budou před importováním všechny uložené překlady v databázi smazány.'} />
                                        </div>
                                    </div>
                                </div>
                            <Button className='col-2' text={<div><i className="fa-solid fa-file-import"></i> Importovat</div>} OnClick={() => this.ImportTranslations()} />
                            </div>
                            <div className={`d-flex flex-column mt-3`}>
                                {this.RenderImportResponse(this.state.response)}
                                {this.state.jsonEmpty && <InfoMessage text={'Nelze importovat prázný seznam!'} />}
                                {this.state.jsonInvalid && <InfoMessage text={'Neplatný formát JSON!'} />}
                                {this.state.truncateTable && <InfoMessage text={'Tímto se zruší spojení překladů s kariérami a slideshow!'} stayVisible />}
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }

}
