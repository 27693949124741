import React, { Component } from 'react';
import '@styles/public/brochures.scss';
import { BreadCrumbs } from '@components/shared/BreadCrumbs';

export class Brochures extends Component {
    static displayName = Brochures.name;

    constructor(props) {
        super(props);

        this.breadCrumbPath = [
            { text: this.props.translate("s_navlink_brochures"), link: "" },
        ];

        this.mobileBreakpoint = 768;

        this.state = {
            brochures: {
                general: [
                    { name: this.props.translate("brochures_vm_sielaff_guide"), path: "s_vm_sielaff_guide_brochure", img: "VendingMachines_product_program_brochure_preview.jpg", horizontal: true, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: this.props.translate("brochures_rvm_sielaff_guide"), path: "s_rvm_sielaff_guide_brochure", img: "VendingMachines_sielaff_guide_brochure_preview.jpg", horizontal: true, shown: !this.IsMobile(), ref: React.createRef() }
                ],
                ad: [           
                    { name: this.props.translate("brochures_main_brochure_name"), path: "s_ad_main_brochure", img: "TB_AutomatedDispensary_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB 300", path: "s_ad_tb_300_brochure", img: "TB_300_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB COMBI", path: "s_ad_tb_combi_brochure", img: "TB_COMBI_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB LOCKER 24", path: "s_ad_tb_locker24_brochure", img: "TB_LOCKER24_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB LOCKER 14", path: "s_ad_tb_locker14_brochure", img: "TB_LOCKER14_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB DRAWER", path: "s_ad_tb_drawer_brochure", img: "TB_DRAWER_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "TB GLOVES", path: "s_ad_tb_gloves_brochure", img: "TB_GLOVES_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() },
                    { name: "EPV 27", path: "s_ad_epv_27_brochure", img: "EVP27_brochure_preview.jpg", horizontal: false, shown: false, ref: React.createRef() }
                ],
                vm: [
                    { name: "SiVend Robimat XL", path: "s_sivend_robimat_xl_brochure", img: "Robimat_XL_Basis_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "SiVend FK 320", path: "s_sivend_fk_320_brochure", img: "FK_320_RB_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "SiVend SN48", path: "s_sivend_sn_48_brochure", img: "SN48_General_Information_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "SiVend SiOne", path: "s_sivend_sione_brochure", img: "SiOne_General_Information_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "SiVend HG 15 TT Espresso", path: "s_sivend_hg_15_tt_espresso_brochure", img: "SiVend_HG_15_TT_Espresso_General_Information_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "SiVend HG 20 TT Espresso", path: "s_sivend_hg_20_tt_espresso_brochure", img: "SiVend_HG_20_Trend_Espresso_General_Information_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },

                    { name: "SiLine Snack and Combi S", path: "s_siline_snack_and_combi_s_brochure", img: "SiLine_Snack_and_Combi_S_SiLine_Combi_S_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "SiLine Snack and Combi M", path: "s_siline_snack_and_combi_m_brochure", img: "SiLine_Snack_and_Combi_M_SiLine_Combi_M_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },

                    { name: "Siamonie CLASSIC TOUCH", path: "s_siamonie_classic_touch_brochure", img: "Siamonie_CLASSIC_TOUCH_General_Information_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { name: "Siamonie Smart OCS", path: "s_siamonie_smart_ocs_brochure", img: "Siamonie_Smart_OCS_General_Information_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },

                    { link: this.props.translateRaw("s_vm_visit_sielaff_link"), shown: !this.IsMobile(), horizontal: false, ref: React.createRef() }
                ],
                rvm: [
                    { name: this.props.translate("brochures_product_program"), path: "s_rvm_product_program_brochure", img: "RVM_product_program_brochure_preview.jpg", horizontal: false, shown: !this.IsMobile(), ref: React.createRef() },
                    { link: this.props.translateRaw("s_rvm_visit_sielaff_link"), shown: !this.IsMobile(), horizontal: false, ref: React.createRef() }
                ]
            },
            adHeading: { shown: false, text: 's_navlink_dispensary', ref: React.createRef()},
            vmHeading: { shown: false, text: 's_navlink_vending_machines', ref: React.createRef()},
            rvmHeading: { shown: false, text: 's_navlink_reverse_vending_machines', ref: React.createRef()}
        }
    }

    componentDidMount() {
        document.title = "Sielaff Bohemia - " + this.props.translateRaw("s_navlink_brochures");
        window.scrollTo({ top: 0 });

        // different fadein animations on mobile and pc
        if (this.IsMobile()) {
            window.addEventListener('scroll', this.HandleScroll);
            this.HandleScroll();
        } else {
            this.BrochuresFadeIn();
        }
    }

    HandleScroll = () => {

        Object.keys(this.state.brochures).forEach((categoryKey) => {
            const brochuresArray = this.state.brochures[categoryKey];

            brochuresArray.forEach((brochure, index) => {
                if (brochure.shown)
                    return;

                this.setState((prevState) => {
                    // Create a copy of the current state
                    let updatedBrochures = { ...prevState.brochures };

                    // Update the specific brochure's shown property
                    updatedBrochures[categoryKey][index] = {
                        ...updatedBrochures[categoryKey][index],
                        shown: this.ShowState(brochure.ref)
                    };

                    // Return the updated state
                    return { brochures: updatedBrochures };
                });
            });
        });

        if (!this.state.adHeading.shown)
            this.setState((prevState) => ({
                adHeading: { ...prevState.adHeading, shown: this.ShowState(this.state.adHeading.ref) }
            }));

        if (!this.state.vmHeading.shown)
            this.setState((prevState) => ({
                vmHeading: { ...prevState.vmHeading, shown: this.ShowState(this.state.vmHeading.ref) }
            }));

        if (!this.state.rvmHeading.shown)
            this.setState((prevState) => ({
                rvmHeading: { ...prevState.rvmHeading, shown: this.ShowState(this.state.rvmHeading.ref) }
            }));
    }

    ShowState = (ref) => {

        if (ref == null || ref.current == null)
            return false;

        return ref.current.getBoundingClientRect().top < (window.screen.height * (this.IsMobile() ? 0.86 : 0.8));
    }

    IsMobile = () => {
        return window.innerWidth < this.mobileBreakpoint;
    }

    BrochuresFadeIn = () => {

        this.state.brochures.general.forEach((brochure, index) => {
            setTimeout(() => {
                this.setState((prevState) => {
                    let updatedBrochures = { ...prevState.brochures };
                    updatedBrochures.general[index] = {
                        ...updatedBrochures.general[index],
                        shown: true
                    };

                    return { brochures: updatedBrochures };
                });
            }, index * 100);
        });

        this.state.brochures.ad.forEach((brochure, index) => {
            setTimeout(() => {
                this.setState((prevState) => {
                    let updatedBrochures = { ...prevState.brochures };
                    updatedBrochures.ad[index] = {
                        ...updatedBrochures.ad[index],
                        shown: true
                    };

                    return { brochures: updatedBrochures };
                });
            }, index * 100);
        });
    }

    RenderBrochure = (b, i) => {
        let showAnimation = b.shown ? (this.IsMobile() ? 'flyIn' : 'fadeIn') : '';

        if (b.link != null)
            return <a role='button' href={b.link} target='_blank' className={`fakeImg m-3 d-flex justify-content-between ${b.horizontal ? 'horizontal' : 'vertical'} ${showAnimation}`} key={i} ref={b.ref}>
                <div className='ms-3 d-flex flex-column ali align-items-center justify-content-center fitW'>
                    <div className='fw-bold'>
                        {this.props.translate("brochures_more_at")}
                    </div>
                    {this.props.translate("s_sielaff_link_label")}
                    <i className={`arrow mt-3 fa-solid fa-chevron-right d-flex`}></i>
                </div>
                <div className='position-absolutee sliderContainer'></div>
            </a>

        let pdfPath = `files/documents/${this.props.translateRaw(b.path)}`;

        return <div className={`brochure rounded fitW mb-2 mb-md-3 p-3 flyInAnimation ${showAnimation}`} key={i} ref={b.ref}>
            <a href={pdfPath} target='_blank' rel='noreferrer'>
                <div className={`img ${b.horizontal ? 'horizontal' : 'vertical'}`}>
                    <img src={`files/images/${b.img}`} alt='Pdf preview' />
                </div>
                <h4 className={`brochureName mt-2 mb-0 ${b.horizontal ? 'horizontal' : 'vertical'}`}>{b.name}</h4>
            </a>
            <a href={pdfPath} download className='redText fitW download d-flex align-items-center'>
                <i className="fa-solid fa-download me-2 redText"></i>
                {this.props.translate("s_download")}
            </a>
        </div>
    }

    RenderBrochures = (brochureList) => {
        return <div className='d-flex flex-wrap justify-content-center justify-content-md-start'>
            {brochureList.map((b, i) =>
                this.RenderBrochure(b, i)
            )}
        </div>
    }

    RenderHeading = (h) => {
        let showAnimation = '';
        if (this.IsMobile())
            showAnimation = h.shown ? 'flyInAnimation flyIn' : 'flyInAnimation';

        return <h2 className={`mt-4 mb-3 ${showAnimation}`} ref={h.ref}>{this.props.translate(h.text)}</h2>
    }

    render() {
        return (
            <div id='brochures' className='containerCustomMain pt-3' ref={this.props.navLinks.brochures.main.ref}>
                <BreadCrumbs
                    translate={this.props.translate}
                    navLinks={this.props.navLinks}
                    HandleLinkClick={this.props.HandleLinkClick}
                    path={this.breadCrumbPath}
                />
                <h1 className='mt-4'>{this.props.translate("brochures_heading")}</h1>

                <h2>{this.props.translate("s_general")}</h2>
                {this.RenderBrochures(this.state.brochures.general)}

                {this.RenderHeading(this.state.adHeading)}
                {this.RenderBrochures(this.state.brochures.ad)}

                {this.RenderHeading(this.state.vmHeading)}
                {this.RenderBrochures(this.state.brochures.vm)}

                {this.RenderHeading(this.state.rvmHeading)}
                {this.RenderBrochures(this.state.brochures.rvm)}
            </div>
        );
    }
}
