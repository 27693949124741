import React, { Component } from 'react';
import '@styles/public/home/reverseVendingMachines.scss'; 
import { Button } from '@components/shared/Button';

export class ReverseVendingMachines extends Component {
    static displayName = ReverseVendingMachines.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className={`position-relative ${this.props.link.main.animate && ' flyInAnimation'} ${(this.props.link.main.animate && this.props.link.main.animationShown) && 'flyIn'}`} id='vendingMachines' ref={this.props.link.main.ref} id='reverseVendingMachines' ref={this.props.link.main.ref}>
                <div className='containerCustomMain'>
                    <h1>{this.props.translate("s_navlink_reverse_vending_machines")}</h1>
                </div>
                {/*<div className='bgImg col-12'></div>*/}
                <figure className='w-100 h-100'>
                    <picture>
                        <source srcSet={`files/images/reverse_vending_machines_mobile.jpg`} media="(min-width: 0px) and (max-width: 576px)" type="image/jpg" />
                        <source srcSet={`files/images/reverse_vending_machines_tablet.jpg`} media="(min-width: 577px) and (max-width: 1200px)" type="image/jpg" />
                        <source srcSet={`files/images/reverse_vending_machines_pc.jpg`} media="(min-width: 1201px) and (max-width: 1920px)" type="image/jpg" />
                        <source srcSet={`files/images/reverse_vending_machines_pc_large.jpg`} media="(min-width: 1921px)" type="image/jpg" />
                        <img src={`files/images/reverse_vending_machines_pc.jpg`} alt={'Úvodní fotografie sekce zálohovací automaty'} />
                    </picture>
                </figure>
                <div className='containerCustomMain mt-3'>
                    <div className='containerCustom'>
                        <ul className='m-0'>
                            {this.props.translate("h_rvm_points")}
                        </ul>
                        <h2 className='mt-4'>{this.props.translate("h_rvm_subheading")}</h2>
                        <div className='d-flex flex-column flex-lg-row justify-content-lg-between containerCustomSm'>
                            <div className='d-flex flex-column col-12 col-lg-5'>
                                {this.props.translate("h_rvm_text")}
                                <div className='mt-2'>
                                    <Button iconClass={"fa-regular fa-file-pdf"} text={this.props.translate("s_show_product_program")} downloadFile={this.props.translateRaw("s_rvm_product_program_brochure")} />
                                </div>
                            </div>
                            <div className='video col-12 col-lg-6 mx-auto mt-4 mt-lg-0'>
                                {this.props.translate("h_rvm_video")}
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 d-flex flex-column mb-1'>
                        <div className='mb-1'>{this.props.translate("h_visit_sielaff")}</div>
                        <div className='fitW'>
                            <a href={this.props.translateRaw("s_rvm_visit_sielaff_link")} rel='noreferrer' target='_blank' className='d-flex'>
                                <Button arrow text={this.props.translate("s_sielaff_link_label")} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
