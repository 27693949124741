import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';

export class NewFile extends Component {
    static displayName = NewFile.name;

    constructor(props) {
        super(props);

        this.state = {

            response: null,
            loading: false,

            file: null,
            fileEmpty: false,
            name: "",
            nameEmpty: false,
            nameHasSpace: false,
            extension: "",
            invalidExtension: false
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetName = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, name: !this.state.file ? "" : val }));
    }

    HandleImportFile = (file) => {

        this.HandleClearResponses();
        if (!file) {
            this.setState((prevState) => ({ ...prevState, file: null, name: "", fileEmpty: true, invalidExtension: false }));
            return;
        }

        console.log(file)

        let extension = this.GetFileExtension(file);
        let invalidExtension = !this.props.extensionList.find(e => e.toLowerCase() == extension);
        this.setState((prevState) => ({ ...prevState, file: file, name: file.name.split('.')[0], invalidExtension: invalidExtension, extension: extension }));
    }

    GetFileExtension = (file) => {
        return !file ? null : `.${file.name.split('.').pop().toLowerCase()}`
    }

    FileToByteArray = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result;
                const byteArray = new Uint8Array(arrayBuffer);
                resolve(Array.from(byteArray)); // Convert Uint8Array to regular array
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    };

    HandleClearResponses = () => {
        this.setState((prevState) => ({
            ...prevState,
            response: null,
            fileEmpty: false,
            nameEmpty: false,
            nameHasSpace: false,
        }));
    }

    UploadFile = async() => {

        if (this.state.fileEmpty || this.state.invalidExtension)
            return;

        let nameEmpty = this.state.name.length <= 0;
        let nameHasSpace = this.state.name.includes(" ");

        this.setState((prevState) => ({ ...prevState, loading: !(nameEmpty || nameHasSpace), nameEmpty: nameEmpty, nameHasSpace: nameHasSpace }));
        if (nameEmpty || nameHasSpace)
            return;

        let byteFile = await this.FileToByteArray(this.state.file);

        axios
            .post(`/api/adm/file/${this.props.type}/create`, {
                Name: this.state.name,
                Extension: this.GetFileExtension(this.state.file),
                File: byteFile
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    response: data.responseCode
                }));

                if (data.responseCode == 200) {
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 600);
                    this.props.GetFileList();
                }

            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Nahrát soubor</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column col-12 mb-3`}>
                        <label htmlFor='file' className=''>Soubor</label>
                        <input type='file' className='' id='file' onChange={(e) => this.HandleImportFile(e.target.files[0])} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${(this.state.nameEmpty || this.state.nameHasSpace) && 'inputError'}`}>
                        <label htmlFor='name'>Nový název</label>
                        <input type='text' id='name' className={`ps-2 ${!this.state.file ? 'readonly' : ''}`} value={this.state.name} readOnly={!this.state.file} onChange={(e) => this.HandleSetName(e.target.value)} />
                    </div>  
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='extension'>Formát <i className="ms-2 fa-solid fa-lock"></i></label>
                        <input type='text' id='extension' className='ps-2 readonly' value={this.state.extension} readOnly />
                    </div>
                    <div className='d-flex justify-content-between pt-3'>
                        <div className='d-flex flex-column'>
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {(this.state.nameEmpty && !(!this.state.file)) && <InfoMessage text={'Vyplňte název!'} />}
                            {(this.state.nameHasSpace && !(!this.state.file)) && <InfoMessage text={'V názvu nesmí být mezera!'} />}
                            {this.state.fileEmpty && <InfoMessage text={'Vyberte soubor!'} stayVisible />}
                            {this.state.invalidExtension && 
                                <div className='d-flex flex-column'>
                                    <div className='d-flex align-items-center'>
                                        <InfoMessage text={`Nepovolený typ souboru`} stayVisible />
                                        <b className='invalidExtension ms-1'>{this.GetFileExtension(this.state.file)}</b>
                                    </div>
                                    <div>Povolené formáty: <b>{this.props.extensionList.join(', ')}</b></div>
                                </div>
                            }
                        </div>
                        <Button className='col-2' text={"Nahrát"} OnClick={() => this.UploadFile()} />
                    </div>
                </div>
            </div>
        );
    }

}
