import React, { Component } from 'react';
import '@styles/adm/translations.scss'; 
import { InfoMessage } from '@components/shared/InfoMessage';
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import AdmHelper from '@model/helper/AdmHelper';
import axios from "axios";
import { Spinner } from '@components/shared/Spinner';
import { Button } from '@components/shared/Button';
import { NewTranslation } from './components/NewTranslation';
import { EditTranslation } from './components/EditTranslation';
import { FilterTranslation } from './components/FilterTranslation';
import { ImportExportTranslation } from './components/ImportExportTranslation';

export class Translations extends Component {
    static displayName = Translations.name;

    constructor(props) {
        super(props);

        this.defaultFilters = {
            isComplete: 'all',
            key: "",
            localizationText: "",
            isActive: 'all',
            page: 'all',
            isCached: 'all',
            editable: 'all',
            paginationCount: 25,
            paginationPage: 1
        };
        if (this.props.forceCareer) {
            this.defaultFilters.isCached = 'false';
        }

        this.state = {

            filters: this.defaultFilters,
            allRowsCount : 0,

            translationList: [],
            response: null,

            loading: true,

            importExportFormOpen: false,
            newFormOpen: false,
            editFormOpen: false,
            editTranslationData: null,

            languageList: [],
            languageListEmpty: false,

            pageList: [],
            pageListEmpty: false,

            showDescription: false
        };

        this.responseHelper = new AdmResponseHelper();
        this.helper = new AdmHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        if(!this.props.forceCareer)
            this.GetTranslationList(this.state.filters);
        this.GetPageList();
        this.GetLanguageList();
    }

    GetLanguageList = async () => {

        return axios
            .get("api/adm/language/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                if (data.responseCode == 200)
                    this.setState((prevState) => ({ ...prevState, languageList: data.languageList }));

                this.setState((prevState) => ({ ...prevState, languageListEmpty: data.languageList.length <= 0 }));
            });
    } 

    GetPageList = async () => {

        return axios
            .get("api/adm/page/list", this.loginHeader)
            .then((response) => {
                let data = response.data;

                if (data.responseCode === 200) {
                    if (this.props.forceCareer) {
                        let slideshowPageId = data.pageList.find(p => p.name == 'career')?.id;
                        this.defaultFilters.page = slideshowPageId;
                        this.HandleSetFilters(this.defaultFilters);
                    }
                }

                this.setState((prevState) => ({ ...prevState, pageList: data.pageList, pageListEmpty: data.pageList.length <= 0 }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, pageListEmpty: true }));
            });
    } 

    GetTranslationList = (filters = this.state.filters) => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        let isComplete = filters.isComplete == 'all' ? null : filters.isComplete === 'true';
        let isActive = filters.isActive == 'all' ? null : filters.isActive === 'true';
        let page = filters.page == 'all' ? null : filters.page;
        let isCached = filters.isCached == 'all' ? null : filters.isCached === 'true';
        let editable = filters.editable == 'all' ? null : filters.editable === 'true';
        let paginationCount = filters.paginationCount == 'all' ? null : filters.paginationCount;

        return axios
            .post("/api/adm/translation/list", {
                IsComplete: isComplete,
                Key: filters.key,
                LocalizationText: filters.localizationText,
                IsActive: isActive,
                IdPage: page,
                IsCached: isCached,
                Editable: editable,
                PaginationCount: paginationCount,
                PaginationPage: filters.paginationPage
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, response: data.responseCode, translationList: data.translationList, allRowsCount: data.allRowsCount }));

                console.log(data)

                if (data.responseCode != 200)
                    return;

                if (this.state.editFormOpen && this.state.editTranslationData != null)
                    this.HandleOpenEditForm(this.state.editTranslationData.id);

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 400:
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    HandleSetFilters = (newFilters) => {
        this.setState((prevState) => ({ ...prevState, filters: newFilters }));
        this.GetTranslationList(newFilters);
    }

    HandleOpenNewForm = () => {
        this.HandleCloseForms();
        let canOpen = !this.state.languageListEmpty && !this.state.pageListEmpty;
        this.setState((prevState) => ({ ...prevState, newFormOpen: canOpen }));
    }

    HandleOpenImportExportForm = () => {
        this.HandleCloseForms();
        this.setState((prevState) => ({ ...prevState, importExportFormOpen: true }));
    }

    HandleCloseForms = () => {
        this.setState((prevState) => ({ ...prevState, newFormOpen: false, editFormOpen: false, importExportFormOpen: false }));
    }

    HandleOpenEditForm = (id) => {

        this.HandleCloseForms();

        if (id == null)
            return;

        let targetTranslation = this.state.translationList.find(t => t.id === id);

        this.setState((prevState) => ({ ...prevState, editFormOpen: true, editTranslationData: targetTranslation }));
    }

    RenderForms = () => {

        if (this.state.editFormOpen && this.state.editTranslationData == null)
            return

        return (
            (this.state.newFormOpen || this.state.editFormOpen || this.state.importExportFormOpen) &&
            <div className='position-fixed h-100 formGlass d-flex justify-content-center pt-5'>
                {this.state.newFormOpen &&
                    <NewTranslation forceCareer={this.props.forceCareer} selectedPage={this.state.filters.page} HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} GetTranslationList={() => this.GetTranslationList()} pageList={this.state.pageList} languageList={this.state.languageList} />
                }
                {this.state.editFormOpen &&
                    <EditTranslation HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} targetTranslation={this.state.editTranslationData} pageList={this.state.pageList} GetTranslationList={() => this.GetTranslationList()} languageList={this.state.languageList} />
                }
                {this.state.importExportFormOpen &&
                    <ImportExportTranslation HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} GetTranslationList={() => this.GetTranslationList()} translationList={this.state.translationList} FiltersActive={this.FiltersActive} />
                }
            </div>
        );
    }

    HandleToggleDescription = () => {
        this.setState((prevState) => ({ ...prevState, showDescription: !this.state.showDescription }));
    }

    FiltersActive = () => {
        let noFilters = JSON.parse(JSON.stringify(this.defaultFilters));
        noFilters.paginationCount = 'all';
        return JSON.stringify(this.state.filters) != JSON.stringify(noFilters);
    }

    RenderTranslationList = () => {

        if (this.state.translationList.length <= 0)
            return <h4 className='d-flex justify-content-center mt-4'>Nebyly nalezeny žádné překlady</h4>

        return (
            this.state.translationList.map((translation) => {
                return <div className={`w-100 position-relative d-flex border-top border-bottom align-items-center py-2 ${(!translation.isComplete && translation.isActive) && 'red'}`} role='button' key={translation.key} onClick={() => this.HandleOpenEditForm(translation.id)}>
                    <div className={`mw col-3 px-1`}>
                        {translation.key}
                        {!translation.editable &&
                            <span className='redText'>*</span>    
                        }
                    </div>
                    <div className='mw col-4 px-1 d-flex flex-column'>
                        {this.helper.ShortenText(translation.internalNote)}
                        <div className='fw-bold'>{this.helper.GetLocalizationCzechOrOtherShort(translation, true, false)}</div>
                    </div>
                    <div className='mw col-1 px-1'>{translation.page.name}</div>
                    <div className='mw col-1 px-1 position-relative'>
                        {translation.isActive ?
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            : 
                            <div className='d-flex align-items-center'>
                                Ne
                                <div className={`colorCircle grayDarker ms-1`}></div>
                            </div>
                    }
                    </div>
                    <div className='mw col-1 px-1 position-relative'>
                        {translation.isComplete ?
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                Ne
                                {translation.isActive ? <div className={`colorCircle red ms-1`}></div> : <div className={`colorCircle grayDarker ms-1`}></div>}
                            </div>
                        }
                    </div>
                    <div className='mw col-1 px-1 d-flex align-items-center'>
                        {translation.localizationList.map((l) => {
                            return (
                                <div className='mx-1' key={l.id}>
                                    {(l.language.imgPath == "" || l.language.imgPath == null) ?
                                        <span className='text-uppercase'>{l.language.name}</span>
                                        :
                                        <img src={`/files/images/${l.language.imgPath}`} alt={`vlajka ${l.language.name}`} />
                                    }
                                </div>
                            );
                        })}
                    </div>
                </div>
            })
        );
    }

    render() {
        return (
            <div id='admTranslation' className={`position-relative ${this.props.noHeight && 'noHeight'} ${this.props.forceCareer && 'mt-5'}`}>
                <div className='mt-3 d-flex flex-column flex-md-row justify-content-md-between'>
                    <div className='d-flex flex-row align-items-center justify-content-between justify-content-sm-start'>
                        {this.props.forceCareer ?
                            <h2 className='m-0'>Správa překladů kariéry</h2>
                            :
                            <h1 className='m-0'>Správa překladů</h1>
                        }
                        <div className='d-flex justify-content-end ms-sm-4'>
                            <Button text={" + Přidat"} OnClick={() => this.HandleOpenNewForm()} />
                        </div>
                    </div>
                    {!this.props.forceCareer &&
                        <div className='mt-2 mt-md-0'>
                            <Button text={<div><i className="fa-solid fa-file-import"></i> Import /export</div>} OnClick={() => this.HandleOpenImportExportForm()} />
                        </div>
                    }
                </div>
                <div className='mt-2' onClick={() => this.HandleToggleDescription()} role='button'>
                    <div className='d-flex align-items-center'>
                        <h4 className='m-0 me-2'>Popis funkčnosti</h4>
                        <i className={`fa-solid fa-chevron-${this.state.showDescription ? 'down' : 'up'}`}></i>
                    </div>
                    {this.state.showDescription &&
                        <ul className='mt-2'>
                            <li>Ve veřejné části se načítají jen aktivní překlady.</li>
                            <li>Každý překlad je vázán na 'stránku' - část aplikace, kde se tyto překlady používají. Např. 'shared' překlady jsou dostupné v rámci celé aplikace, 'home' překlady se načítají jen na hlavní stránce aplikace atd. 'Slideshow' překlady jsou načítány také na hlavní stránce, ale jen ty, které jsou použité v aktivní slideshow.</li>
                            <li>Na stránce 'career' jsou klasické překlady, ale i překlady typu 'překlad kariéry'. Jedná se o překlady vázané na konkrétní kariéru - překlad názvu kariéry, její popis atd. Nejedná se o standardní překlady používané na stránce, o tyto překlady je žádáno až v případě požadavku o konkrétní kariéru a načtou se již přeložené.</li>
                            <li>Kompletní = přeloženo do všech aktivních jazyků.</li>
                            <li>Klíče jednotlivých překladů poté musí začínat prefixem vázané stránky.</li>
                            <li>Překlady s <span className='redText'>*</span> nelze upravit. Jejich změna vyžaduje změnu kódu aplikace.</li>
                        </ul>
                    }

                </div>
                {this.RenderResponse()}
                {this.state.languageListEmpty &&
                    <InfoMessage text={"Nebyly nalezeny žádné jazyky!"} stayVisible />    
                }
                {this.state.pageListEmpty &&
                    <div className='mt-3'>
                        <InfoMessage text={"Nebyly nalezeny žádné stránky!"} stayVisible />
                    </div>
                }
                <div className='mt-3'>
                    <div className='position-relative '>
                        <FilterTranslation forceCareer={this.props.forceCareer} defaultFilters={this.defaultFilters} filters={this.state.filters} HandleSetFilters={(f) => this.HandleSetFilters(f)} loggedAdmin={this.props.loggedAdmin} allRowsCount={this.state.allRowsCount} pageList={this.state.pageList} translationListCount={this.state.translationList.length} />
                        <div className='overflow-auto'>
                            <div className='col-12 d-flex mt-3'>
                                <div className='mw col-3 px-1 fw-bold'>Key</div>
                                <div className='mw col-4 px-1 fw-bold'>Popis | překlad</div>
                                <div className='mw col-1 px-1 fw-bold'>Stránka</div>
                                <div className='mw col-1 px-1 fw-bold'>Aktivní</div>
                                <div className='mw col-1 px-1 fw-bold'>Kompletní</div>
                                <div className='mw col-1 px-1 fw-bold'>Přeloženo</div>
                            </div>
                            {this.state.loading ?
                                <div className='m-4'><Spinner dark /></div>
                                :
                                <div className='translationList'>
                                    <div className='records mb-4'>
                                        {this.RenderTranslationList()}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.RenderForms()}
            </div>
        );
    }
}
