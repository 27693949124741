
import React, { Component } from 'react';
import '@styles/public/home/contactForm.scss'; 
import axios from "axios";
import { Button } from '@components/shared/Button';
import { InfoMessage } from '@components/shared/InfoMessage';

export class ContactForm extends Component {
    static displayName = ContactForm.name;

    constructor(props) {
        super(props);

        this.emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;    // x@x.x

        this.state = {
            name: "",
            nameError: false,
            surname: "",
            surnameError: false,
            email: "",
            emailError: false,
            phone: "",
            subject: "",
            subjectError: "",
            message: "",
            messageError: "",

            sendResponse: null,
            sending: false
        };
    }

    HandleSubmitForm = () => {

        let inputsValid = this.ValidateInputs();
        if (!inputsValid)
            return;

        this.setState((prevState) => ({
            ...prevState,
            sending: true
        }));

        axios.post('/api/public/mail/send', {
            name: this.state.name,
            surname: this.state.surname,
            email: this.state.email,
            phone: this.state.phone,
            subject: this.state.subject,
            message: this.state.message
        })
            .then(response => {

                let data = response.data;
                this.setState((prevState) => ({
                    ...prevState,
                    sendResponse: data.responseCode,
                    emailError: data.responseCode == 400
                }));

                if (data.responseCode == 200)
                    this.RefreshFormState();
            })
            .catch(error => {
                this.setState((prevState) => ({
                    ...prevState,
                    sendResponse: 500
                }));
            }).finally(f => {

                this.setState((prevState) => ({
                    ...prevState,
                    sending: false
                }));

                setTimeout(() => {
                    this.setState((prevState) => ({
                        ...prevState,
                        sendResponse: null
                    }));
                }, 5000);
            });
    }

    RefreshFormState = () => {
        this.setState((prevState) => ({
            ...prevState,
            name: "",
            nameError: false,
            surname: "",
            surnameError: false,
            email: "",
            emailError: false,
            phone: "",
            subject: "",
            subjectError: "",
            message: "",
            messageError: "",
        }));
    }

    ValidateInputs = () => {

        let nameValid = this.state.name.length > 0;
        let surnameValid = this.state.surname.length > 0;
        let emailValid = this.emailRegex.test(this.state.email);
        let subjectValid = this.state.subject.length > 0;
        let messageValid = this.state.message.length > 0;
        
        this.setState((prevState) => ({
            ...prevState,
            nameError: !nameValid,
            surnameError: !surnameValid,
            emailError: !emailValid,
            subjectError: !subjectValid,
            messageError: !messageValid,
        }));
            
        return (
            nameValid &&
            surnameValid &&
            emailValid &&
            subjectValid &&
            messageValid
        );
    }

    RenderSendResponse = () => {

        switch (this.state.sendResponse) {
            case 200:
                return <InfoMessage text={this.props.translate("h_contact_form_response_success")} isSuccess />;
            case 400:
            case 500:
                return <InfoMessage text={this.props.translate("h_contact_form_response_fail")} />;
            default:
                return;
        }
    }

    RenderLoading = () => {
        return (
            <div className='position-absolute w-100 h-100 glass d-flex align-items-center justify-content-center'>
                <div className="loading spinner-border " role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    HandleSetName = (val) => {
        this.setState((prevState) => ({...prevState, name: val, nameError: false }));
    }

    HandleSetSurname = (val) => {
        this.setState((prevState) => ({...prevState, surname: val, surnameError: false }));
    }

    HandleSetEmail = (val) => {
        this.setState((prevState) => ({ ...prevState, email: val, emailError: false }));
    }

    HandleSetSubject = (val) => {
        this.setState((prevState) => ({ ...prevState, subject: val, subjectError: false }));
    }

    HandleSetMessage = (val) => {
        this.setState((prevState) => ({ ...prevState, message: val, messageError: false }));
    }

    render() {
        return (
            <section id='contactForm' ref={this.props.link.ref} className={`mh-auto pt-4 containerCustom ${this.props.link.animate && ' flyInAnimation'} ${(this.props.link.animate && this.props.link.animationShown) && 'flyIn'}`}>
                <h2 className='mb-3'>{this.props.translate("h_contact_us")}</h2>
                <div className='d-flex flex-column containerCustomSm'>
                    <div className='d-flex justify-content-between flex-wrap position-relative'>
                        {this.state.sending && this.RenderLoading()}
                        <div className={`d-flex flex-column inputContainer mb-3 col-12 ${this.state.nameError ? 'inputError' : ''}`}>
                            <label className='d-flex' htmlFor='name'>{this.props.translate("s_name")}*</label>
                            <input type='text' id='name' name='name' className='p-1 ps-2' value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column inputContainer mb-3 col-12 ${this.state.surnameError ? 'inputError' : ''}`}>
                            <label className='d-flex' htmlFor='surname'>{this.props.translate("s_surname")}*</label>
                            <input type='text' id='surname' name='surname' className='p-1 ps-2' value={this.state.surname} onChange={(e) => this.HandleSetSurname(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column inputContainer mb-3 col-12 ${this.state.emailError ? 'inputError' : ''}`}>
                            <label className='d-flex' htmlFor='email'>Email*</label>
                            <input type='text' id='email' name='email' className='p-1 ps-2' placeholder='' value={this.state.email} onChange={(e) => this.HandleSetEmail(e.target.value)} />
                        </div>
                        <div className='d-flex flex-column inputContainer mb-3 col-12'>
                            <label htmlFor='phone'>{this.props.translate("s_phone")}</label>
                            <input type='text' id='phone' name='phone' className='p-1 ps-2' value={this.state.phone} onChange={(e) => this.setState((prevState) => ({ ...prevState, phone: e.target.value }))} />
                        </div>
                        <div className={`d-flex flex-column inputContainer mb-3 col-12 subject ${this.state.subjectError ? 'inputError' : ''}`}>
                            <label className='d-flex' htmlFor='subject'>{this.props.translate("s_subject")}*</label>
                            <input type='text' id='subject' name='subject' className='p-1 ps-2' value={this.state.subject} onChange={(e) => this.HandleSetSubject(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column inputContainer mb-3 col-12 message ${this.state.messageError ? 'inputError' : ''}`}>
                            <label className='d-flex' htmlFor='message'>{this.props.translate("s_message")}*</label>
                            <textarea id='message' name='message' value={this.state.message} onChange={(e) => this.HandleSetMessage(e.target.value)} />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                            {(this.state.nameError || this.state.surnameError || this.state.emailError || this.state.subjectError || this.state.messageError) && <InfoMessage text={this.props.translate("h_contact_form_fill_all_mandatory")} stayVisible />}
                            {this.state.emailError && <InfoMessage text={this.props.translate("h_contact_form_invalid_email")} stayVisible />}
                            {this.RenderSendResponse()}
                        </div>
                        <Button text={this.props.translate("s_send")} OnClick={() => this.HandleSubmitForm()} />
                    </div>
                </div>
            </section>
        );
    }
}
