import React, { Component } from 'react';
export class FlyInAnimation extends Component {
    static displayName = FlyInAnimation.name;

    constructor(props) {
        super(props);

        this.mobileBreakpoint = 992;
        this.state = {
            isMobile: this.IsMobile(),
            shown: false,
            ref: React.createRef()
        };

        this.product = this.props.product;
    }

    componentDidMount() {
        window.addEventListener('resize', this.HandleResize);
        window.addEventListener('scroll', this.HandleScroll);
        this.HandleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.HandleResize);
        window.removeEventListener('scroll', this.HandleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shown && !prevState.shown)
            window.removeEventListener('scroll', this.HandleScroll);

        if (this.state.ref.current != null && prevState.ref.current == null)
            this.HandleScroll();
    }

    HandleResize = () => {
        let isMobile = this.IsMobile();
        if (this.state.isMobile != isMobile)
            this.setState((prevState) => ({ ...prevState, isMobile: isMobile }))
    }

    HandleScroll = () => {
        this.setState((prevState) => ({ ...prevState, shown: this.ShowState() }));
    }

    ShowState = () => {

        if (this.state.ref == null || this.state.ref.current == null)
            return false;

        return this.state.ref.current.getBoundingClientRect().top < (window.screen.height * (this.state.isMobile ? 0.86 : 0.8));
    }

    IsMobile = () => {
        return window.innerWidth < this.mobileBreakpoint;
    }

    render() {
        return (
            <div className={`flyInAnimation ${this.state.shown ? 'flyIn' : ''}`} ref={this.state.ref} >
                {this.props.component}
            </div>
        );
    }
}
