import React, { Component } from 'react';
import { Button } from '@components/shared/Button';
import '@styles/shared/productShowcase.scss'; 
import ShowMore from '@components/shared/ShowMore';

export class ProductShowcase extends Component {
    static displayName = ProductShowcase.name;

    constructor(props) {
        super(props);

        this.mobileBreakpoint = 768;
        this.state = {
            isMobile: this.IsMobile()
        }
        this.product = this.props.product;
    }

    componentDidMount() {
        window.addEventListener('resize', this.HandleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.HandleResize);
    }

    HandleResize = () => {
        let isMobile = this.IsMobile();
        if (this.state.isMobile != isMobile)
            this.setState((prevState) => ({ ...prevState, isMobile: isMobile }))
    }

    IsMobile = () => {
        return window.innerWidth < this.mobileBreakpoint;
    }

    RenderBrochureButton = () => {                       
        return <Button iconClass={"fa-regular fa-file-pdf"} text={this.props.downloadBrochureText} inverted downloadFile={this.product.download} />;
    }

    render() {
        return (
            <section className={`productShowcase d-flex flex-column flex-lg-row ${this.props.first ? 'first' : ''} ${this.props.last ? 'last' : ''}`}>
                {this.state.isMobile &&
                    <h1 className='mb-4'>{this.product.heading}</h1>                                       
                }
                <div className='mainImg mx-auto mb-4'>
                    <img src={`files/images/${this.product.imgMain}`} alt={`Automated dispensary ${this.product.heading}`} className='' />
                </div>
                <div className='d-flex flex-column col'>
                    {!this.state.isMobile &&
                        <div className='borderTop pt-2 position-relative'>
                            <h1 className='mb-3'>{this.product.heading}</h1>
                            {this.product.download && 
                                <div className='position-absolute downloadAbsolute end-0'>
                                    {this.RenderBrochureButton()}
                                </div>
                            }
                        </div>
                    }
                    <h5 className='mb-1'>{this.product.subheading}</h5>
                    <ul>
                        {this.product.points}
                    </ul>   
                    {this.product.text && 
                        <ShowMore text={this.product.text} label={this.props.translate("ad_show_more")} />
                    }
                    {(this.product.imgVer || this.product.imgHor) &&
                        <div className={`subImgs mt-4 d-flex flex-column align-items-center justify-content-center flex-md-row w-100 justify-content-lg-end ${this.product.imgHor ? '' : ''}`}>
                            {this.product.imgVer &&
                                <img src={`files/images/${this.product.imgVer}`} alt={`Automated dispensary ${this.product.heading} 2`} className={`ver ${this.product.imgHor ? 'mt-3' : 'showOnMobile me-auto ms-5'} mt-md-0 order-2`} />
                            }
                            {this.product.imgHor &&
                                (this.product.imgVer ?
                                    <img src={`files/images/${this.product.imgHor}`} alt={`Automated dispensary ${this.product.heading} 3`} className='hor order-md-3' />
                                    :
                                    <img src={`files/images/${this.product.imgHor}`} alt={`Automated dispensary ${this.product.heading} 3`} className='fill' />
                                )
                            }
                        </div>
                    }
                    {(this.product.download && this.state.isMobile) &&
                        <div className='mt-1'>
                            {this.RenderBrochureButton()}
                        </div>
                    }
                </div>
            </section>
        );
    }
}
