import React, { Component } from 'react';
import axios from "axios";
import '@styles/adm/users.scss'; 
import { Spinner } from '@components/shared/Spinner';
import { Button } from '@components/shared/Button';
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { NewRole } from './components/NewRole';
import { EditRole } from './components/EditRole';
import { NewUser } from './components/NewUser';
import { EditUser } from './components/EditUser';

export class Users extends Component {
    static displayName = Users.name;

    constructor(props) {
        super(props);

        this.state = {

            userList: [],
            userListResponse: null,

            userRoleList: [],
            userRoleListResponse: null,

            editUserData: null,
            editRoleData: null,

            openedForm: null,

            loadingUserList: true,
            loadingUserRoleList: true,
        }

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };

        this.FormType = {
            NEWUSER: 'NEWUSER',
            EDITUSER: 'EDITUSER',
            NEWROLE: 'NEWROLE',
            EDITROLE: 'EDITROLE'
        };
    }

    componentDidMount() {
        this.GetUserRolesList();
        this.GetUserList();
    }

    GetUserRolesList = (refreshUserList = false) => {

        if (refreshUserList)
            this.GetUserList();

        this.setState((prevState) => ({ ...prevState, loadingUserRoleList: true }));
        return axios
            .get("/api/adm/user/role/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, userRoleListResponse: data.responseCode, userRoleList: data.roleList }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, userRoleListResponse: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loadingUserRoleList: false }));
            });
    }

    GetUserList = () => {

        this.setState((prevState) => ({ ...prevState, loadingUserList: true }));

        return axios
            .get("/api/adm/user/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, userListResponse: data.responseCode, userList: data.userList }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, userListResponse: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loadingUserList: false }));
            });
    }

    RenderResponse = (response) => {

        switch (response) {
            case 400:
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    RenderUserListResponse = () => {
        return this.RenderResponse(this.state.userListResponse);
    }

    RenderUserRoleListResponse = () => {
        return this.RenderResponse(this.state.userRoleListResponse);
    }

    HandleOpenUserNewForm = () => {
        this.setState((prevState) => ({ ...prevState, openedForm: this.FormType.NEWUSER }));
    }

    HandleOpenRoleNewForm = () => {
        this.setState((prevState) => ({ ...prevState, openedForm: this.FormType.NEWROLE }));
    }

    HandleCloseForms = () => {
        this.setState((prevState) => ({ ...prevState, openedForm: null }));
    }

    HandleOpenUserEditForm = (id) => {

        this.HandleCloseForms();

        if (id == null)
            return;

        let targetUser = this.state.userList.find(a => a.id === id);

        this.setState({ ...this.state, editUserData: targetUser, openedForm: this.FormType.EDITUSER});
    }

    HandleOpenRoleEditForm = (id) => {

        this.HandleCloseForms();

        if (id == null)
            return;

        let targetRole = this.state.userRoleList.find(a => a.id === id);

        this.setState({ ...this.state, openedForm: this.FormType.EDITROLE, editRoleData: targetRole });
    }

    RenderForms = () => {

        let form;
        switch (this.state.openedForm) {
            case this.FormType.NEWUSER:
                if (this.state.userRoleList.length <= 0) return;                   
                form = <NewUser HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} GetUserList={() => this.GetUserList()} userRoleList={this.state.userRoleList} />
                break;
            case this.FormType.EDITUSER:
                form = <EditUser HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} targetUser={this.state.editUserData} GetUserList={() => this.GetUserList()} userRoleList={this.state.userRoleList} />
                break;
            case this.FormType.NEWROLE:
                form = <NewRole HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} GetRoleList={() => this.GetUserRolesList()} />
                break;
            case this.FormType.EDITROLE:
                form = <EditRole HandleCloseForm={() => this.HandleCloseForms()} loggedAdmin={this.props.loggedAdmin} targetRole={this.state.editRoleData} GetRoleList={() => this.GetUserRolesList()} />
                break;
            default:
                return
        }

        return (
            <div className='position-fixed h-100 formGlass d-flex justify-content-center pt-5'>
                {form}
            </div>
        );
    }

    RenderUserList = () => {

        return (
            this.state.userList.map((user) => {
                return <div className='col-12 d-flex border-top border-bottom align-items-center py-2' role='button' key={user.id} onClick={() => this.HandleOpenUserEditForm(user.id) }>
                    <div className='col-1 mx-1 minW'>{user.id}</div>
                    <div className='col-2 mx-1 minW'>{user.role.name}</div>
                    <div className='col-2 mx-1 minW'>{user.username}</div>
                    <div className='col-3 mx-1 minW'>{user.description}</div>
                    <div className='col-1 mx-1 minW'>
                        {user.isActive ?
                        <div className='d-flex align-items-center'>
                            Ano
                            <div className={`colorCircle green ms-1`}></div>
                        </div>
                        :
                        <div className='d-flex align-items-center'>
                            Ne
                            <div className={`colorCircle grayDarker ms-1`}></div>
                        </div>
                        }
                    </div>
                    <div className='col-2 mx-1 minW'>{user.createdAt}</div>
                </div>
            })
        );
    }

    RenderUserRoleList = () => {
        return (
            this.state.userRoleList.map((role) => {
                return <div className='col-12 d-flex border-top border-bottom align-items-center py-2' role='button' key={role.id} onClick={() => this.HandleOpenRoleEditForm(role.id)}>
                    <div className='col-1 mx-1 minW'>{role.id}</div>
                    <div className='col-3 mx-1 minW'>{role.name}</div>
                    <div className='col-2 mx-1 minW'>
                        {role.isActive ?
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                Ne
                                <div className={`colorCircle grayDarker ms-1`}></div>
                            </div>
                        }
                    </div>
                    <div className='col-5 mx-1 minW'>{role.description}</div>
                </div>
            })
        );
    }

    render() {
        return (
            <div id='admUsers' className='position-relative'>
                <div className='mt-3 d-flex flex-column flex-sm-row align-items-sm-center'>
                    <h1 className='m-0'>Správa uživatelů</h1>
                    <div className='d-flex justify-content-end ms-sm-4'>
                        <Button text={" + Přidat"} OnClick={() => this.HandleOpenUserNewForm() } />
                    </div>
                </div>
                {this.RenderUserListResponse()}
                {this.RenderUserRoleListResponse()}
                <div className='mt-4'>
                    <div className='overflow-auto'>
                        <div className='col-12 d-flex mt-3'>
                            <div className='col-1 mx-1 fw-bold minW'>ID</div>
                            <div className='col-2 mx-1 fw-bold minW'>Role</div>
                            <div className='col-2 mx-1 fw-bold minW'>Username</div>
                            <div className='col-3 mx-1 fw-bold minW'>Popis</div>
                            <div className='col-1 mx-1 fw-bold minW'>Aktivní</div>
                            <div className='col-2 mx-1 fw-bold minW'>Založen</div>
                        </div>
                        {this.state.loadingUserList ?
                            <div className='m-4'><Spinner dark /></div>
                            :
                            <div className='col-12 list mb-4 position-relative'>
                                {this.RenderUserList()}
                            </div>
                        }
                    </div>
                </div>
                <div className='mt-4'>
                    <div className='overflow-auto'>
                        <div className='mt-4 d-flex flex-column flex-sm-row align-items-sm-center'>
                            <h2 className='m-0'>Správa rolí</h2>
                            <div className='d-flex justify-content-end ms-sm-4'>
                                <Button text={" + Přidat"} OnClick={() => this.HandleOpenRoleNewForm()} disabled />
                            </div>
                        </div>
                        <div className='col-12 d-flex mt-3'>
                            <div className='col-1 mx-1 fw-bold minW'>ID</div>
                            <div className='col-3 mx-1 fw-bold minW'>Název</div>
                            <div className='col-2 mx-1 fw-bold minW'>Aktivní</div>
                            <div className='col-5 mx-1 fw-bold minW'>Popis</div>
                        </div>
                        {this.state.loadingUserRoleList ?
                            <div className='m-4'><Spinner dark /></div>
                            :
                            <div className='col-12 list mb-4 position-relative'>
                                {this.RenderUserRoleList()}
                            </div>
                        }
                    </div>
                </div>
                {this.RenderForms()}
            </div>
        );
    }
}
