import React, { Component } from 'react';
import '@styles/shared/breadCrumbs.scss';

export class BreadCrumbs extends Component {
    static displayName = BreadCrumbs.name;

    constructor (props) {
        super(props);

        this.state = {
            ref: React.createRef(),
            inRow: true
        }
    }

    componentDidMount() {

        window.addEventListener('resize', this.HandleResize);

        this.InRowWidth = this.state.ref?.current.offsetWidth;
        this.setState((prevState) => ({
            ...prevState,
            inRow: this.GetShouldBeInRow()
        }))
    }

    EmptyFunction = () => {
        // This function does nothing
    }

    HandleResize = () => {
        this.setState((prevState) => ({
            ...prevState,
            inRow: this.GetShouldBeInRow()
        }))
    }

    GetShouldBeInRow = () => {

        if (!this.state.ref.current)
            return;

        return this.InRowWidth < window.document.documentElement.clientWidth - 60; // - pevny padding z .containerCustom
    }

    render() {
        return <div className={`breadCrumbs fitW d-flex ${this.state.inRow ? 'align-items-center' : 'flex-column'}`} ref={this.state.ref}>
            {!this.props.noHome &&
                <div className='segment d-flex align-items-center' role="button" onClick={() => this.props.HandleLinkClick(this.props.navLinks.slideShow.main)}>
                    {this.props.translate("s_home")}
                    <i className={`mx-2 redText fa-solid fa-chevron-${this.state.inRow ? 'right' : 'down'}`}></i>
                </div>
            }
            {Object.keys(this.props.path).map((key, i) => {

                let last = (i === this.props.path.length - 1);
                let onClick = (last) ? () => this.EmptyFunction() : () => this.props.HandleLinkClick(this.props.path[key].link);

                return <div key={key} className={`segment d-flex align-items-center ${last ? 'current' : ''}`} role={`${last ? '' : 'button'}`} onClick={onClick}>
                    {this.props.path[key].text}
                    {!last && <i className={`mx-2 redText fa-solid fa-chevron-${this.state.inRow ? 'right' : 'down'}`}></i>}
                </div>
            })}
        </div>
    }
        
}
