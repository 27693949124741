import React, { Component } from 'react';
import '@styles/public/vendingMachinesDetail.scss';
import { Button } from '@components/shared/Button';
import { ProductShowcase } from '@components/shared/ProductShowcase';
import { BreadCrumbs } from '@components/shared/BreadCrumbs';
import { FlyInAnimation } from '@components/shared/FlyInAnimation';

export class VendingMachinesDetail extends Component {
    static displayName = VendingMachinesDetail.name;

    constructor(props) {
        super(props);
        this.translate = this.props.translate;
        this.translateRaw = this.props.translateRaw;

        this.products = {
            sivend: [
                {
                    heading: "Robimat XL",
                    subheading: this.translate("vm_sivend_robimat_xl_subheading"),
                    points: this.translate("vm_sivend_robimat_xl_points"),
                    download: this.translateRaw("s_sivend_robimat_xl_brochure"),
                    imgMain: "product_Robimat_XL_main.png",
                },
                {
                    heading: "FK 320",
                    subheading: this.translate("vm_sivend_fk_320_subheading"),
                    points: this.translate("vm_sivend_fk_320_points"),
                    download: this.translateRaw("s_sivend_fk_300_brochure"),
                    imgMain: "product_FK_320_RB_main.png",
                },
                {
                    heading: "SN48",
                    subheading: this.translate("vm_sivend_sn_48_subheading"),
                    points: this.translate("vm_sivend_sn_48_points"),
                    download: this.translateRaw("s_sivend_sn_48_brochure"),
                    imgMain: "product_SN48_RB_main.png",
                },
                {
                    heading: "SiOne",
                    subheading: this.translate("vm_sivend_sione_subheading"),
                    points: this.translate("vm_sivend_sione_points"),
                    download: this.translateRaw("s_sivend_sione_brochure"),
                    imgMain: "product_SiOne_RB_Flaschen_Dose_Becher_bunt_main.png",
                },
                {
                    heading: "SiVend HG 15 TT Espresso",
                    subheading: this.translate("vm_sivend_hg_15_tt_espresso_subheading"),
                    points: this.translate("vm_sivend_hg_15_tt_espresso_points"),
                    download: this.translateRaw("s_sivend_hg_15_tt_espresso_brochure"),
                    imgMain: "product_SiVend_HG_15_TT_main.png",
                },
                {
                    heading: "SiVend HG 20 TT Espresso",
                    subheading: this.translate("vm_sivend_hg_20_tt_espresso_subheading"),
                    points: this.translate("vm_sivend_hg_20_tt_espresso_points"),
                    download: this.translateRaw("s_sivend_hg_20_tt_espresso_brochure"),
                    imgMain: "product_SiVend_HG_20_Trend_RB_main.png",
                },
            ],
            siline: [
                {
                    heading: "SiLine Snack and Combi S",
                    subheading: this.translate("vm_siline_snack_and_combi_s_subheading"),
                    points: this.translate("vm_siline_snack_and_combi_s_points"),
                    download: this.translateRaw("s_siline_snack_and_combi_s_brochure"),
                    imgMain: "product_SiLine_Snack_S_main.png",
                },                
                {
                    heading: "SiLine Snack and Combi M",
                    subheading: this.translate("vm_siline_snack_and_combi_m_subheading"),
                    points: this.translate("vm_siline_snack_and_combi_m_points"),
                    download: this.translateRaw("s_siline_snack_and_combi_m_brochure"),
                    imgMain: "product_SiLine_Snack_M_main.png",
                }
            ],
            siamonie: [
                {
                    heading: "Siamonie CLASSIC TOUCH",
                    subheading: this.translate("vm_siamonie_classic_touch_subheading"),
                    points: this.translate("vm_siamonie_classic_touch_points"),
                    download: this.translateRaw("s_siamonie_classic_touch_brochure"),
                    imgMain: "product_Siamonie_Classic_Touch_main.png",
                },
                {
                    heading: "Siamonie Smart OCS",
                    subheading: this.translate("vm_siamonie_smart_ocs_subheading"),
                    points: this.translate("vm_siamonie_smart_ocs_points"),
                    download: this.translateRaw("s_siamonie_smart_ocs_brochure"),
                    imgMain: "product_Siamonie_Smart_OCS__Display_main.png",
                }
            ]
        }

        this.state = {
            siVendRef: React.createRef(),
            siLineRef: React.createRef(),
            siamonieRef: React.createRef()
        }

        this.breadCrumbPath = [
            { text: this.translate("s_navlink_vending_machines"), link: this.props.navLinks.ad.main},
            { text: this.translate("s_navlink_vending_machines_sublink"), link: ""},
        ];

    }

    componentDidMount() {
        window.scrollTo({ top: 0 });
        document.title = "Sielaff Bohemia - " + this.translateRaw("s_navlink_vending_machines");
    }

    HandleNavigateToVmLine = (ref) => {
        
        if (ref == null || ref.current == null)
            return;

        let targetY = window.scrollY + ref.current.getBoundingClientRect().top - this.GetNavHeight();
        window.scrollTo({ top: targetY, behavior: "smooth" });
    }

    GetNavHeight = () => {
        return window.window.innerWidth <= 768 ? 61 : 70
    }

    render() {
        return (
            <div id='vendingMachinesDetail' className='containerCustomMainBigger' ref={this.props.navLinks.ad.subLinks.adDetail.ref}>
                <section className='intro position-relative pb-0'>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex flex-column col mt-3'>
                            <BreadCrumbs
                                translate={this.props.translate}
                                navLinks={this.props.navLinks}
                                HandleLinkClick={this.props.HandleLinkClick}
                                path={this.breadCrumbPath}
                            />
                            <div className='d-flex flex-column mt-4'>
                                <h1 className='d-flex align-items-center pt-3'>
                                    <img src='/files/images/tb.png' alt='logo TB' className='me-3' />
                                    {this.props.translate("vm_heading")}
                                </h1>                                                               
                                <div className='d-flex flex-column col justify-content-between'>
                                    <div className='d-flex flex-column col mb-2'>
                                        {this.props.translate("vm_text_1")}
                                        <ul className='vm_points'>
                                            <li role='button' className='fitW' onClick={() => this.HandleNavigateToVmLine(this.state.siVendRef)}>SiVend</li>
                                            <li role='button' className='fitW' onClick={() => this.HandleNavigateToVmLine(this.state.siLineRef) }>SiLine®</li>
                                            <li role='button' className='fitW' onClick={() => this.HandleNavigateToVmLine(this.state.siamonieRef)}>Siamonie</li>
                                        </ul>
                                        {this.props.translate("vm_text_2")}
                                        {this.props.translate("vm_all_types_visit_sielaff")}

                                    </div>
                                    <div className='mt-2'>
                                        <Button iconClass={"fa-regular fa-file-pdf"} text={this.translate("vm_view_sielaff_guide")} downloadFile={this.props.translateRaw("s_vm_sielaff_guide_brochure")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bgImg d-none d-md-flex col-12 col-sm-6 ms-5'></div>
                    </div>
                </section>

                <section className='d-flex flex-column mt-4 mt-md-5'>
                    <FlyInAnimation component={
                        <h1 className='d-flex align-items-center blackText mb-4 mb-md-5 pt-5' ref={this.state.siVendRef}>
                            {this.props.translate("vm_sivend_heading")}
                        </h1>
                    } />
                    {Object.keys(this.products.sivend).map((key, i) => {
                        return <FlyInAnimation key={i} component={
                            <ProductShowcase first={i === 0} last={this.products.sivend.length - 1 === i} product={this.products.sivend[key]} translate={this.props.translate} downloadBrochureText={this.translate("s_view_brochure")} />
                        } />
                    })}
                </section>

                <section className='d-flex flex-column mt-4 mt-md-5'>

                    <FlyInAnimation component={
                        <h1 className='d-flex align-items-center blackText mb-4 pt-5' ref={this.state.siLineRef}>
                            {this.props.translate("vm_siline_heading")}
                        </h1>
                    } />

                    <FlyInAnimation component={
                        <>
                            <div className='mb-1'>
                                {this.props.translate("vm_siline_text")}
                            </div>
                            <div className='col-12 d-flex justify-content-center justify-content-md-start'>
                                <div className='video col-12'>
                                    <iframe src="https://www.youtube.com/embed/nP3Na-Ee1k0" origin="https://sielaff.cz" title="SIELAFF | SiLine®" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                                <div className='videoEffect col'></div>
                            </div> 
                        </>
                    } />

                    {Object.keys(this.products.siline).map((key, i) => {
                        return <FlyInAnimation key={i} component={
                            <ProductShowcase last={this.products.siline.length-1 === i} product={this.products.siline[key]} translate={this.props.translate} downloadBrochureText={this.translate("s_view_brochure")} />
                        } />        
                    })}
                </section>

                <section className='d-flex flex-column mt-4 mt-md-5'>
                    <FlyInAnimation component={
                        <h1 className='d-flex align-items-center blackText mb-4 mb-md-5 pt-5' ref={this.state.siamonieRef}>
                            {this.props.translate("vm_siamonie_heading")}
                        </h1>
                    } />

                    {Object.keys(this.products.siamonie).map((key, i) => {
                        return <FlyInAnimation key={i} component={
                            <ProductShowcase first={i === 0} last={this.products.siamonie.length - 1 === i} product={this.products.siamonie[key]} translate={this.props.translate} downloadBrochureText={this.translate("s_view_brochure")} />
                        } />        
                    })}
                </section>

                <div className='mt-5'>
                    {this.props.translate("vm_all_types_visit_sielaff")}
                </div>
            </div>
        );
    }
}
