import React, { Component } from 'react';
import PageHelper from '@model/helper/PageHelper';
import { Spinner } from '@components/shared/Spinner';
import '@styles/public/home/careers.scss'; 

export class Careers extends Component {
    static displayName = Careers.name;

    constructor(props) {
        super(props);

        this.maxPageRows = 6;
        this.translate = this.props.translate;
        this.translateRaw = this.props.translateRaw;
        this.controller = new PageHelper();

        this.state = {
            currentPage: 1,
            pages: 1,
            careerList: [],
            loading: true,
        };
    }

    componentDidMount() {

        this.setState((prevState) => ({
            ...prevState,
            loading: true
        }));

        this.controller.GetCareerList(this.props.language).then((response) => {

            if (localStorage.getItem("debug") === "1")
                console.log(response);

            this.setState((prevState) => ({
                ...prevState,
                careerList: response.careerList,
                loading: false,
                pages: Math.ceil(response.careerList.length / this.maxPageRows)
            }));
        });
    }

    RenderPageNumbers = () => {

        for (let i = 1; i <= this.state.pages+1; i++) {

            let className = 'm-2 px-2';
            if (i == this.state.currentPage)
                className += ' current';

            return <span role='button' key={i} className={className} onClick={() => this.HandleSetPageNumber(i)} >{i}</span>
        }
    }

    HandleSetPageNumber = (n) => {
        this.setState((prevState) => ({
            ...prevState,
            currentPage: n
        }));
    }

    RenderCareerList = () => {

        if (this.state.careerList.length <= 0)
            return <h5 className='d-flex justify-content-center align-items-center my-auto p-3'>
                {this.translate("h_no_available_careers")}
            </h5>

        let endIndex = this.state.currentPage * this.maxPageRows;
        let startIndex = endIndex - this.maxPageRows;
        let careersOnPage = this.state.careerList.slice(startIndex, endIndex);

        return (

            careersOnPage.map((career) => {

                let careerRef = `${this.translateRaw(this.props.link.subLinks.careerDetail.ref)}/${career.nameUrl}`;

                return (
                    <a href={careerRef} key={career.nameUrl} className='d-flex justify-content-between align-items-center w-100 p-2 px-4 flex-column flex-lg-row'>
                        <span className='fw-bold d-flex align-items-center py-2 w-100 justify-content-start'>{career.translationName}</span>
                        <div className='w-100 d-flex justify-content-between align-items-center justify-content-lg-end'>
                            <span className='branch d-flex justify-content-lg-center me-lg-5'>{career.translationBranch}</span>
                            <div className='d-flex justify-content-center align-items-center justify-content-lg-end ms-lg-5'>
                                <span className='me-3'>{career.translationLocation}</span>
                                <img src='./files/images/arrow.png' alt='šipka' className='' />
                            </div>
                        </div>
                    </a>
                )
            })
        );
    }

    render() {
        return (
            <section id='careers' className={`${this.props.link.main.animate && ' flyInAnimation'} ${(this.props.link.main.animate && this.props.link.main.animationShown) && 'flyIn'}`} ref={this.props.link.main.ref}>
                <h1 className=''>{this.translate("s_navlink_career")}</h1>
                <div className='containerCustom d-flex flex-column justify-content-center align-items-center'>
                    <h2 className='mb-3'>{this.translate("h_career_currently_available_careers")}</h2>
                    {
                        this.state.loading ?
                            <div className='mt-3'>
                                <Spinner small />
                            </div>
                            :
                            <>
                                <div className='careers d-flex flex-column w-100'>
                                    {this.RenderCareerList()}
                                </div>
                                {this.state.careerList.length > 0 &&
                                    <div className='pages m-4'>
                                        {this.RenderPageNumbers()}
                                    </div>
                                }
                            </>
                    }
                </div>
            </section>
        );
    }
}
