import React, { Component } from 'react';
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { BackButton } from '@components/shared/BackButton';
import { SelectTranslation } from '@components/shared/SelectTranslation';
import ShowMore from '@components/shared/ShowMore';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { InfoMessage } from '@components/shared/InfoMessage';

export class SlideEdit extends Component {
    static displayName = SlideEdit.name;

    constructor(props) {
        super(props);

        this.createDefaultSlide = {
            id: 0,
            imgMobile: 'default',
            imgTablet: 'default',
            imgPc: 'default',
            imgPcLarge: 'default',
            order: 99,
            duration: 6000,
            idTranslationHeading: 'default',
            idTranslationText: 'default',
            fontColor: "#fdfdfd",
            captionMobileX: "10%",
            captionMobileY: "75%",
            captionX: "10%",
            captionY: "75%",
            isActive: false,
        };


        this.isEdit = !(!this.props.currentEditSlide);

        this.defaultSlide = this.isEdit ? this.props.currentEditSlide : this.createDefaultSlide;
        this.defaultSlide.idTranslationHeading = this.defaultSlide.idTranslationHeading == null ? 'default' : this.defaultSlide.idTranslationHeading;
        this.defaultSlide.idTranslationText = this.defaultSlide.idTranslationText == null ? 'default' : this.defaultSlide.idTranslationText;

        this.state = {
            slide: this.defaultSlide,

            invalidImgMobile: false,
            invalidImgTablet: false,
            invalidImgPc: false,
            invalidImgPcLarge: false,
            invalidOrder: false,
            invalidDuration: false,
            invalidFontColor: false,
            invalidCaptionMobileX: false,
            invalidCaptionMobileY: false,
            invalidCaptionX: false,
            invalidCaptionY: false,

            createResponse: null,
            deleteResponse: null,
            editResponse: null,

            showDeleteConfirmation: false,

            loading: false,
        }

        this.fileList = this.props.fileList;
        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.props.HandleSetPreviewSlides([this.defaultSlide]);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.slide != this.state.slide) {
            this.props.HandleSetPreviewSlides([this.state.slide]);
        }
    }

    HandleToggleDeleteConfirmation = () => {
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation }));
    }

    DeleteSlide = () => {

        this.setState((prevState) => ({
            ...prevState,
            loading: true
        }));

        axios
            .delete(`/api/adm/slideshow/delete/${this.state.slide.id}`, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    deleteResponse: data.responseCode,
                }));

                if (data.responseCode == 200) {
                    setTimeout(() => {
                        this.props.HandleOpenListSlide(true);
                    }, 600);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, deleteResponse: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    CreateSlide = () => {

        let imgMobileValid = this.ImageIsValid(this.state.slide.imgMobile);
        let imgTabletValid = this.ImageIsValid(this.state.slide.imgTablet);
        let imgPcValid = this.ImageIsValid(this.state.slide.imgPc);
        let imgPcLargeValid = this.ImageIsValid(this.state.slide.imgPcLarge);
        let orderValid = this.state.slide.order > 0;
        let durationValid = this.state.slide.duration > 0;
        let fontColorValid = this.IsHexColor(this.state.slide.fontColor);
        let captionMobileXValid = this.state.slide.captionMobileX.length > 0;
        let captionMobileYValid = this.state.slide.captionMobileY.length > 0;
        let captionXValid = this.state.slide.captionX.length > 0;
        let captionYValid = this.state.slide.captionY.length > 0;

        let valid = (imgMobileValid && imgTabletValid && imgPcValid && imgPcLargeValid && orderValid && durationValid && fontColorValid && captionXValid && captionYValid && captionMobileXValid && captionMobileYValid);

        this.setState((prevState) => ({
            ...prevState,
            invalidImgMobile: !imgMobileValid,
            invalidImgTablet: !imgTabletValid,
            invalidImgPc: !imgPcValid,
            invalidImgPcLarge: !imgPcLargeValid,
            invalidOrder: !orderValid,
            invalidDuration: !durationValid,
            invalidFontColor: !fontColorValid,
            invalidCaptionMobileX: !captionMobileXValid,
            invalidCaptionMobileY: !captionMobileYValid,
            invalidCaptionX: !captionXValid,
            invalidCaptionY: !captionYValid,
            loading: valid
        }));

        if (!valid)
            return;

        let createDto = JSON.stringify(this.state.slide);
        createDto = JSON.parse(createDto);
        createDto.idTranslationHeading = createDto.idTranslationHeading === 'default' ? null : createDto.idTranslationHeading;
        createDto.idTranslationText = createDto.idTranslationText === 'default' ? null : createDto.idTranslationText;

        axios
            .post("/api/adm/slideshow/create", createDto, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    createResponse: data.responseCode,
                }));

                if (data.responseCode == 200) {
                    setTimeout(() => {
                        this.props.HandleOpenListSlide(true);
                    }, 600);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, createResponse: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditSlide = () => {

        let imgMobileValid = this.ImageIsValid(this.state.slide.imgMobile);
        let imgTabletValid = this.ImageIsValid(this.state.slide.imgTablet);
        let imgPcValid = this.ImageIsValid(this.state.slide.imgPc);
        let imgPcLargeValid = this.ImageIsValid(this.state.slide.imgPcLarge);
        let orderValid = this.state.slide.order > 0;
        let durationValid = this.state.slide.duration > 0;
        let fontColorValid = this.IsHexColor(this.state.slide.fontColor);
        let captionMobileXValid = this.state.slide.captionMobileX.length > 0;
        let captionMobileYValid = this.state.slide.captionMobileY.length > 0;
        let captionXValid = this.state.slide.captionX.length > 0;
        let captionYValid = this.state.slide.captionY.length > 0;

        let valid = (imgMobileValid && imgTabletValid && imgPcValid && imgPcLargeValid && orderValid && durationValid && fontColorValid && captionMobileXValid && captionMobileYValid && captionXValid && captionYValid);

        this.setState((prevState) => ({
            ...prevState,
            invalidImgMobile: !imgMobileValid,
            invalidImgTablet: !imgTabletValid,
            invalidImgPc: !imgPcValid,
            invalidImgPcLarge: !imgPcLargeValid,
            invalidOrder: !orderValid,
            invalidDuration: !durationValid,
            invalidFontColor: !fontColorValid,
            invalidCaptionMobileX: !captionMobileXValid,
            invalidCaptionMobileY: !captionMobileYValid,
            invalidCaptionX: !captionXValid,
            invalidCaptionY: !captionYValid,
            loading: valid
        }));

        if (!valid)
            return;

        let editDto = JSON.stringify(this.state.slide);
        editDto = JSON.parse(editDto);
        editDto.idTranslationHeading = editDto.idTranslationHeading === 'default' ? null : editDto.idTranslationHeading;
        editDto.idTranslationText = editDto.idTranslationText === 'default' ? null : editDto.idTranslationText;

        axios
            .patch("/api/adm/slideshow/edit", editDto, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    editResponse: data.responseCode,
                }));

                if (data.responseCode == 200) {
                    setTimeout(() => {
                        this.props.HandleOpenListSlide(true);
                    }, 600);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, editResponse: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleSetDefaultSlide = () => {
        this.setState((prevState) => ({
            ...prevState,
            slide: this.defaultSlide,
            invalidImgMobile: false,
            invalidImgTablet: false,
            invalidImgPc: false,
            invalidImgPcLarge: false,
            invalidOrder: false,
            invalidDuration: false,
            invalidFontColor: false,
            invalidCaptionMobileX: false,
            invalidCaptionMobileY: false,
            invalidCaptionX: false,
            invalidCaptionY: false,
        }));
    }

    RenderPreviewImg = (img, text) => {

        let emptyImgClass = "";
        if (!this.ImageIsValid(img))
            emptyImgClass = "empty";

        return <div className='d-flex flex-column'>
            <div>{text}</div>
            <div className={`previewImg ${emptyImgClass} border`}>
                <img src={`/files/images/${img}`} alt='' className={`${text == 'Mobil' && 'mobile'} w-100 h-100`} />
            </div>
        </div>
    }

    ImageIsValid = (img) => {

        if (!img)
            return false;

        for (let i = 0; i < this.fileList.length; i++) {
            let file = this.fileList[i];
            if (this.GetFileFullName(file).toLowerCase() === img.toLowerCase())
                return true;
        }

        return false;
    }

    IsHexColor(hex) {
        return typeof hex === 'string'
            && hex.length === 7
            && hex[0] === '#'
    }

    GetFileFullName = (file) => {
        return file.name + '' + file.extension;
    }

    RenderShowMore = () => {
        let text = <div>
            <span className='fw-bold'>Doporučené rozměry obrázků (š:v)</span>
            <ul className=''>
                <li>
                    <span className='fw-bold'>Mobil</span>
                    : Do 575px, obrázek 575x1140px (1:2)
                </li>
                <li>
                    <span className='fw-bold'>Tablet</span>
                    : 576px až 1200px, obrázek 1200x600px (2:1)
                </li>
                <li>
                    <span className='fw-bold'>Pc</span>
                    : 1201px až 1920px, obrázek 1920x768px (2,5:1)
                </li>
                <li>
                    <span className='fw-bold'>Pc+</span>
                    : Od 1921px, obrázek 2560x850px (3:1)
                </li>
            </ul>
        </div>;
        return <ShowMore text={text} label={"Zobrazit více"} />                   
    }

    render() {

        return (
            <div className='createView'>
                <BackButton isAdm text={'Zpět do menu slideshow'} HandleLinkClick={this.props.HandleOpenListSlide} />
                <h1 className='mb-2'>
                    {this.isEdit ? 
                        'Upravit slide'
                        :
                        'Přidat slide'
                    }
                </h1>
                {this.RenderShowMore()}
                <div className='mt-4 w-md-75 mx-auto position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='d-flex flex-column flex-md-row justify-content-md-between'>
                        <div className='d-flex align-items-start flex-column col-12 col-md-6'>
                            <div className='col-12'>
                                <div className='d-flex flex-column align-items-center flex-lg-row'>
                                    <div className='col-6 d-flex justify-content-center'>
                                        <div className='d-flex'>{this.RenderPreviewImg(this.state.slide.imgMobile, "Mobil")}</div>
                                    </div>
                                    <div className='col-6 d-flex justify-content-center'>
                                        <div className='d-flex'>{this.RenderPreviewImg(this.state.slide.imgTablet, "Tablet")}</div>                                        
                                    </div>
                                </div>
                                <div className='d-flex flex-column align-items-center flex-lg-row'>
                                    <div className='col-6 d-flex justify-content-center'>
                                        <div className='d-flex'>{this.RenderPreviewImg(this.state.slide.imgPc, "Pc")}</div>
                                    </div>
                                    <div className='col-6 d-flex justify-content-center'>
                                        <div className='d-flex'>{this.RenderPreviewImg(this.state.slide.imgPcLarge, "Pc+")}</div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-column col-12 mt-md-2 mt-3'>
                                <div className={`d-flex flex-column mb-2 col-12 ${this.state.invalidImgMobile ? ' inputError' : ''}`}>
                                    <label htmlFor='imgMobile'>Obrázek mobil* (575x1140px)</label>
                                    <select id='imgMobile' value={this.state.slide.imgMobile} onChange={(e) => this.HandleSetImgMobile(e.target.value)} className='ps-2'>
                                        <option value='default'>Vyberte</option>
                                        {this.fileList.map((f, i) => {
                                            let fullName = this.GetFileFullName(f);
                                            return <option value={fullName} key={i} className=''>
                                                {fullName}
                                            </option>
                                        })}
                                    </select>
                                </div>
                                <div className={`d-flex flex-column mb-2 col-12 ${this.state.invalidImgTablet ? ' inputError' : ''}`}>
                                    <label htmlFor='imgTablet'>Obrázek tablet* (1200x600px)</label>
                                    <select id='imgTablet' value={this.state.slide.imgTablet} onChange={(e) => this.HandleSetImgTablet(e.target.value)} className='ps-2'>
                                        <option value='default'>Vyberte</option>
                                        {this.fileList.map((f, i) => {
                                            let fullName = this.GetFileFullName(f);
                                            return <option value={fullName} key={i} className=''>
                                                {fullName}
                                            </option>
                                        })}
                                    </select>
                                </div>
                                <div className={`d-flex flex-column mb-2 col-12 ${this.state.invalidImgPc ? ' inputError' : ''}`}>
                                    <label htmlFor='imgPc'>Obrázek Pc* (1920x768px)</label>
                                    <select id='imgPc' value={this.state.slide.imgPc} onChange={(e) => this.HandleSetImgPc(e.target.value)} className='ps-2'>
                                        <option value='default'>Vyberte</option>
                                        {this.fileList.map((f, i) => {
                                            let fullName = this.GetFileFullName(f);
                                            return <option value={fullName} key={i} className=''>
                                                {fullName}
                                            </option>
                                        })}
                                    </select>
                                </div>
                                <div className={`d-flex flex-column mb-2 col-12 ${this.state.invalidImgPcLarge ? ' inputError' : ''}`}>
                                    <label htmlFor='imgPcLarge'>Obrázek Pc+* (2560x850px)</label>
                                    <select id='imgPcLarge' value={this.state.slide.imgPcLarge} onChange={(e) => this.HandleSetImgPcLarge(e.target.value)} className='ps-2'>
                                        <option value='default'>Vyberte</option>
                                        {this.fileList.map((f, i) => {
                                            let fullName = this.GetFileFullName(f);
                                            return <option value={fullName} key={i} className=''>
                                                {fullName}
                                            </option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column mt-2 mt-md-0 col-12 col-md-5'>
                            <div className='mb-2 d-xl-flex mb-xl-0 justify-content-xl-between'>
                                <div className='d-flex flex-column mb-2 mb-xl-0 flex-xl-row align-items-xl-center col-xl-3'>
                                    <label htmlFor='id' className='me-xl-1'>ID</label>
                                    <input id='id' type='number' className='disabled col-12' value={this.state.slide.id} disabled/>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='me-xl-3'>
                                        <SliderOnOff text={'Aktivní'} reversed isChecked={this.state.slide.isActive} OnChange={this.HandleToggleActive} />
                                    </div>
                                    <Button text={"Resetovat nastavení"} small OnClick={this.HandleSetDefaultSlide} />
                                </div>
                            </div>
                            <div className='d-flex flex-column position-relative'>
                                <SelectTranslation mb2 withNote optional text={'Překlad nadpisu'} selectedId={this.state.slide.idTranslationHeading} OnChange={this.HandleSetHeadingTranslation} translationList={this.props.translationList} />
                            </div>
                            <div className='d-flex flex-column position-relative'>
                                <SelectTranslation mb2 withNote optional text={'Překlad textu'} selectedId={this.state.slide.idTranslationText} OnChange={this.HandleSetTextTranslation} translationList={this.props.translationList} />
                            </div>
                            <div className={`d-flex flex-column mb-2 ${this.state.invalidOrder ? ' inputError' : ''}`}>
                                <label htmlFor='order'>Pořadí slidu*</label>
                                <input id='order' type='number' value={this.state.slide.order} onChange={(e) => this.HandleSetOrder(e.target.value.replace(/[^0-9]/g, ""))} />
                            </div>
                            <div className={`d-flex flex-column mb-2 ${this.state.invalidDuration ? ' inputError' : ''}`}>
                                <label htmlFor='duration'>Trvání (ms)*</label>
                                <input id='duration' type='number' value={this.state.slide.duration} onChange={(e) => this.HandleSetDuration(e.target.value.replace(/[^0-9]/g, ""))} />
                            </div>
                            <div className={`d-flex flex-column mb-2`}>
                                <label htmlFor='fontColor'>Barva fontu popisku (hex)</label>
                                <div className={`d-flex align-items-center ${this.state.invalidFontColor ? ' inputError' : ''}`}>
                                    <input type='text' className='col me-2' value={this.state.slide.fontColor} onChange={(e) => this.HandleSetFontColor(e.target.value)} />
                                    <input type='color' value={this.state.slide.fontColor} onChange={(e) => this.HandleSetFontColor(e.target.value)} />
                                </div>
                            </div>
                            <div className='d-flex flex-column col-12 mt-1'>
                                <span>Pozice levého horního rohu popisku (50px, 50%, 25vh atd.)</span>
                                <div className=''>MOBIL</div>
                                <div className='d-flex flex-column flex-xl-row'>
                                    <div className={`d-flex align-items-center mb-2 mb-xl-0 me-xl-3 col ${this.state.invalidCaptionMobileX ? ' inputError' : ''}`}>
                                        <label htmlFor='captionMobileX' className='me-1'>X:</label>
                                        <input id='captionMobileX' className='col' type='text' value={this.state.slide.captionMobileX} onChange={(e) => this.HandleSetCaptionMobileX(e.target.value)} />
                                    </div>
                                    <div className={`d-flex align-items-center col ${this.state.invalidCaptionY ? ' inputError' : ''}`}>
                                        <label htmlFor='captionMobileY' className='me-1'>Y:</label>
                                        <input id='captionMobileY' className='col' type='text' value={this.state.slide.captionMobileY} onChange={(e) => this.HandleSetCaptionMobileY(e.target.value)} />
                                    </div>
                                </div>
                                <div className='mt-2'>OSTATNÍ</div>
                                <div className='d-flex flex-column flex-xl-row'>
                                    <div className={`d-flex align-items-center mb-2 mb-xl-0 me-xl-3 col ${this.state.invalidCaptionX ? ' inputError' : ''}`}>
                                        <label htmlFor='captionX' className='me-1'>X:</label>
                                        <input id='captionX' className='col' type='text' value={this.state.slide.captionX} onChange={(e) => this.HandleSetCaptionX(e.target.value)} />
                                    </div>
                                    <div className={`d-flex align-items-center col ${this.state.invalidCaptionY ? ' inputError' : ''}`}>
                                        <label htmlFor='captionY' className='me-1'>Y:</label>
                                        <input id='captionY' className='col' type='text' value={this.state.slide.captionY} onChange={(e) => this.HandleSetCaptionY(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column mt-3'>
                        <div className='d-flex justify-content-end'>
                            {this.isEdit ?
                                <div className='d-flex justify-content-between col'>
                                    <div className='d-flex'>
                                        <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat'} OnClick={() => this.HandleToggleDeleteConfirmation()} />
                                        {this.state.showDeleteConfirmation &&
                                            <div className='ms-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeleteSlide()} /></div>
                                        }
                                    </div>
                                    <Button className='col-2' text={"Upravit"} OnClick={() => this.EditSlide()} />
                                </div>
                                :
                                <Button className='col-2' text={"Přidat"} OnClick={() => this.CreateSlide()} />
                            }
                        </div>
                        <div className='d-flex flex-column mt-2'>
                            {this.responseHelper.RenderCreateResponse(this.state.createResponse)}
                            {this.responseHelper.RenderDeleteResponse(this.state.deleteResponse)}
                            {this.responseHelper.RenderEditResponse(this.state.editResponse)}
                            {this.state.invalidImgMobile && <InfoMessage text={"Vyberte platný obrázek pro mobil!"} />}
                            {this.state.invalidImgTablet && <InfoMessage text={"Vyberte platný obrázek pro tablet!"} />}
                            {this.state.invalidImgPc && <InfoMessage text={"Vyberte platný obrázek pro pc!"} />}
                            {this.state.invalidImgPcLarge && <InfoMessage text={"Vyberte platný obrázek pro pc+!"} />}
                            {this.state.invalidOrder && <InfoMessage text={"Pořadí musí být číslo větší než 0!"} />}
                            {this.state.invalidDuration && <InfoMessage text={"Doba trvání musí být číslo větší než 0!"} />}
                            {this.state.invalidFontColor && <InfoMessage text={"Barva textu musí být v hexadecimálním tvaru!"} />}
                            {this.state.invalidCaptionMobileX && <InfoMessage text={"Pozice mobil X není vyplněna!"} />}
                            {this.state.invalidCaptionMobileY && <InfoMessage text={"Pozice mobil Y není vyplněna!"} />}
                            {this.state.invalidCaptionX && <InfoMessage text={"Pozice X není vyplněna!"} />}
                            {this.state.invalidCaptionY && <InfoMessage text={"Pozice Y není vyplněna!"} />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    HandleSetImgMobile = (img) => {
        let invalid = !this.ImageIsValid(img)
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, imgMobile: img }, invalidImgMobile: invalid }));
    }

    HandleSetImgTablet = (img) => {
        let invalid = !this.ImageIsValid(img)
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, imgTablet: img }, invalidImgTablet: invalid }));
    }

    HandleSetImgPc = (img) => {
        let invalid = !this.ImageIsValid(img)
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, imgPc: img }, invalidImgPc: invalid }));
    }

    HandleSetImgPcLarge = (img) => {
        let invalid = !this.ImageIsValid(img)
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, imgPcLarge: img }, invalidImgPcLarge: invalid }));
    }

    HandleSetOrder = (o) => {
        let invalid = o <= 0;
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, order: o }, invalidOrder: invalid }));
    }

    HandleSetFontColor = (fontColor) => {
        let invalid = !this.IsHexColor(fontColor);
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, fontColor: fontColor }, invalidFontColor: invalid }));
    }

    HandleSetCaptionMobileX = (x) => {
        let invalid = x.length <= 0;
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, captionMobileX: x }, invalidCaptionMobileX: invalid }));
    }

    HandleSetCaptionMobileY = (y) => {
        let invalid = y.length <= 0;
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, captionMobileY: y }, invalidCaptionMobileY: invalid }));
    }

    HandleSetCaptionX = (x) => {
        let invalid = x.length <= 0;
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, captionX: x }, invalidCaptionX: invalid }));
    }

    HandleSetCaptionY = (y) => {
        let invalid = y.length <= 0;
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, captionY: y }, invalidCaptionY: invalid }));
    }

    HandleToggleActive = () => {
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, isActive: !this.state.slide.isActive } }));
    }

    HandleSetHeadingTranslation = (id) => {
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, idTranslationHeading: id } }));
    }

    HandleSetTextTranslation = (id) => {
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, idTranslationText: id } }));
    }

    HandleSetDuration = (d) => {
        let invalid = d <= 0;
        this.setState((prevState) => ({ ...prevState, slide: { ...this.state.slide, duration: d }, invalidDuration: invalid }));
    }
}
