import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '@styles/shared/navbar.scss';

import {
    Collapse,
    Navbar,
    NavbarBrand,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

export class NavBar extends Component {
    static displayName = NavBar.name;

    constructor (props) {
        super(props);

        this.state = {
            collapsed: true,
            collapseToggled: 0,
            dropdownOpen: false,
            isMobile: this.IsMobile()
        };
        this.forceCollapse = 0;

        if (this.props.isAdm || !this.props.languagesImages)
            return;

        this.languagesImages = [...this.props.languagesImages];
        const currentLanguageImageIndex = this.languagesImages.findIndex(obj => obj.code === this.props.currentLanguage);
        this.currentLanguage = this.languagesImages.splice(currentLanguageImageIndex, 1)[0];
        this.ChangeLanguage = this.props.changeLanguage;

        if (this.props.isErrorPage)
            return;

        this.translate = this.props.translate;
        this.navLinks = this.props.navLinks;
    }

    componentDidMount() {
        window.addEventListener('resize', this.HandleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.HandleResize);
    }

    HandleResize = () => {
        let isMobile = this.IsMobile();
        if (!this.props.isAdm && this.state.isMobile != isMobile)
            this.setState((prevState) => ({ ...prevState, isMobile: isMobile }))
    }

    componentDidUpdate() {
        if ((this.props.forceCollapse != this.forceCollapse) && !this.props.isAdm) {
            this.forceCollapse = this.props.forceCollapse;
            this.setState({
                ...this.state,
                collapsed: true
            });
        }
    }

    toggleNavbar = () => {
        this.setState({
            ...this.state,
            collapsed: !this.state.collapsed,
            collapseToggled: 1
        });
    }

    toggleDropdown = () => {
        this.setState({
            ...this.state,
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    IsMobile = () => {
        return window.innerWidth < 768;
    }

    RenderLanguageLink = (lang, whiteText = false) => {
        return (
            <>
                <span className={`${whiteText && 'whiteText'}`}>{lang.name}</span>
                <img
                    src={'./files/images/' + lang.imgPath}
                    alt={lang.name + ' country flag'}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "./files/images/default.png";
                        currentTarget.alt = "Default country flag";
                    }}
                />
            </>
        );
    }

    RenderHamburgerToggler = () => {

        let className = 'fa ';
        className += this.state.collapsed ? 'fa-bars' : 'fa-times';
        return (
            <div className='togglerContainer sliderContainer' data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleNavbar}>
                <i className={`${className}`}></i>
            </div>
        );
    }

    RenderAdmNavbar = () => {

        if (!this.props.isLoggedIn)
            return (
                <header className="sticky-top gray">
                    <Navbar className='red-border-bottom ng-white d-flex flex-nowrap'>
                        <NavbarBrand tag={Link} to="/">
                            <img src={'/files/images/logo_with_space.png'} alt="Logo Sielaff Bohemia" />
                        </NavbarBrand>
                        <img className='imgSlider' src='/files/images/slider.svg' alt='dekorativní obrázek'/>
                    </Navbar>
                </header>
            );

        return (
            <header className="sticky-top gray">
                <Navbar className="navbar-expand-md navbar-toggleable-md red-border-bottom ng-white" light>
                    <NavbarBrand tag={Link} to="/" target='_blank'>
                        <img src="/files/images/logo_with_space.png" alt="Logo Sielaff Bohemia" />
                    </NavbarBrand>

                    {this.RenderHamburgerToggler()}

                    <Collapse className="d-md-inline-flex" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav d-flex align-items-center col-12 mb-2 m-md-0">
                            <div className='w-100 links m-0 mt-3 mt-md-0 p-0 d-flex flex-column align-items-center flex-md-row mt-md-auto mb-md-2'>
                                {this.props.pages.map((page, i) => {

                                    let renderSeparator = i < this.props.pages.length - 1;

                                    return (
                                        <div className='d-flex align-items-center' key={page.name}>
                                            <NavItem className={`d-flex align-items-center align-items-md-end justify-content-center m-1 m-md-0 p-0 ${this.props.currentPage == page.name && 'selected'}`} onClick={() => window.location.href = page.link}>
                                                {page.linkName}
                                            </NavItem>
                                            {renderSeparator &&
                                                <div className='separator mx-2'>|</div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} direction="down" className="dropdown-button d-flex align-items-center justify-content-end">
                                <div className='d-flex align-items-center justify-content-end'>
                                    <NavItem role="button" className='d-flex align-items-center m-0 p-0' onClick={() => this.props.LogOff()}>
                                        <a href='/' className='pe-md-2 fw-bold logOff fitW'>Odhlásit</a>
                                    </NavItem>
                                </div>
                            </Dropdown>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }

    RenderPublicNavbar = () => {
        return (
            <header className="sticky-top">
                <Navbar className={`navbar-expand-md navbar-toggleable-md ng-white redBottomBorder`} light>

                    {this.props.currentPage === 'home' ? 
                        <NavbarBrand role="button" onClick={() => this.props.HandleLinkClick(this.props.navLinks.slideShow.main)} className={``}>
                            <img src={'/files/images/logo_with_space.png'} alt="Logo Sielaff Bohemia" />
                        </NavbarBrand>
                        :
                        <NavbarBrand tag={Link} to='/' role="button" className={``}>
                            <img src={'/files/images/logo_with_space.png'} alt="Logo Sielaff Bohemia" />
                        </NavbarBrand>
                    }


                    {this.RenderHamburgerToggler()}

                    <Collapse className={`d-md-inline-flex`} isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav d-flex align-items-center align-items-md-stretch col-12 mb-2 m-md-0">
                            <div className={`${this.state.isMobile ? 'mobile' : ''} w-100 links m-0 mt-3 mt-md-0 p-0 d-flex flex-column align-items-center flex-md-row mt-md-auto pb-md-0 flex-wrap`}>
                                {Object.keys(this.navLinks).filter(key => this.navLinks[key].showInNavbar).map((key, i) => {

                                    const link = this.navLinks[key].main;
                                    let subLinksArray = Object.values(this.navLinks[key].subLinks);
                                    let selected;
                                    if (this.props.currentPage === 'home') {
                                        selected = link.refName === this.props.currentSection || subLinksArray.some(sl => sl.refName === this.props.currentSection);
                                    } else {
                                        selected = (link.pageName === this.props.currentPage) || subLinksArray.some(sl => sl.pageName === this.props.currentPage);
                                    }

                                    let renderSeparator = !this.state.isMobile && i < Object.keys(this.navLinks).filter(key => this.navLinks[key].showInNavbar).length - 1;

                                    return <div className={`mainWithSeparator d-flex justify-content-center align-items-center`} key={i}>

                                        {this.RenderPublicPageLink(link, selected, key)}
                                      
                                        {renderSeparator ?
                                            <div className='separator mx-2'>|</div>
                                            :
                                            !this.state.isMobile && <div className='me-3'></div>
                                        }
                                    </div>                                   
                                })}
                            </div>
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} direction="down" className={`dropdown-button ${this.state.isMobile ? 'mt-2' : ''}`}>
                                <DropdownToggle caret>
                                    {this.RenderLanguageLink(this.currentLanguage, true)}
                                </DropdownToggle>
                                <DropdownMenu className={``}>
                                    {this.languagesImages.map((lang) => {
                                        return (
                                            <DropdownItem key={lang.name} onClick={() => { this.props.lsh.SetScrollTo(this.props.currentSection); this.ChangeLanguage(lang.code) }}>
                                                {this.RenderLanguageLink(lang)}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }

    RenderPublicPageLink = (link, selected, key) => {

        const RenderSubLink = (subLink) => {
            return <>
                {this.translate(subLink.translation)}
                <i className='redText ms-2 fa-solid fa-chevron-right'></i>
            </>
        }

        const RenderLink = (link, selected, key) => {

            let showLinks = this.navLinks[key].showSubLinks;
            return <div className={`main d-flex ${this.state.isMobile ? 'flex-column m-1' : 'hover'} position-relative align-items-center justify-content-center p-0`} key={link.name}>

                <div className={`d-flex align-items-center ${selected && ' selected'}`} onClick={() => this.props.HandleLinkClick(link)}>
                    {this.translate(link.translation)}
                    {(showLinks && !this.state.isMobile) &&
                        <i className='redText ms-1 fa-solid fa-chevron-down'></i>
                    }
                </div>

                {showLinks &&
                    <>
                        <div className={`subLinks ${this.state.isMobile ? '' : 'position-absolute'}`}>
                            <div>
                                {Object.keys(this.navLinks[key].subLinks).map((subLinkKey) => {
                                    let subLink = this.navLinks[key].subLinks[subLinkKey];
                                    let subLinkSelected = subLink.pageName === this.props.currentPage;

                                    return subLink.pageName === 'home' ?
                                        <NavItem className={`${subLinkSelected ? 'selected' : ''} ${this.state.isMobile ? '' : 'p-2'} d-flex align-items-center justify-content-between `} onClick={(e) => { this.props.HandleLinkClick(subLink); e.stopPropagation(); }} key={subLinkKey}>
                                            {RenderSubLink(subLink)}
                                        </NavItem>
                                        :
                                        <a href={`/${this.props.translateRaw(subLink.ref)}`} key={subLinkKey} className={`${subLinkSelected ? 'selected' : ''} ${this.state.isMobile ? '' : 'p-2'} d-flex align-items-center justify-content-between`}>
                                            {RenderSubLink(subLink)}
                                        </a>
                                })}
                            </div>
                        </div>
                    </>
                }
            </div>
        };

        return link.pageName === 'home' ?
            RenderLink(link, selected, key)
            :
            <a href={`/${this.props.translateRaw(link.ref)}`}>
                {RenderLink(link, selected, key)}
            </a>
    }

    RenderErrorPageNavBar = () => {

        if (!this.languagesImages)
            return (
                <header className="sticky-top">
                    <Navbar className="navbar-expand-md navbar-toggleable-md redBottomBorder ng-white" light>
                        <NavbarBrand role='button' onClick={() => window.location.href = '/'}>
                            <img src={'/files/images/logo_with_space.png'} alt="Logo Sielaff Bohemia" />
                        </NavbarBrand>

                        <div className='togglerContainer sliderContainer' data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleNavbar}></div>
                        <Collapse className="d-md-inline-flex justify-content-md-end" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav d-flex align-items-center">
                                <div className='links d-flex m-0 p-0 flex-column align-items-center justify-content-between flex-md-row'></div>
                                <Dropdown></Dropdown>
                            </ul>
                        </Collapse>
                    </Navbar>
                </header>
            );

        return (
            <header className="sticky-top">
                <Navbar className="navbar-expand-md navbar-toggleable-md red-border-bottom ng-white" light>
                    <NavbarBrand role='button' onClick={() => window.location.href = '/'}>
                        <img src={'/files/images/logo_with_space.png'} alt="Logo Sielaff Bohemia" />
                    </NavbarBrand>

                    {this.RenderHamburgerToggler()}

                    <Collapse className="d-md-flex justify-content-end" isOpen={!this.state.collapsed} navbar>
                        <ul className={`${this.state.isMobile ? '' : 'fitW'} navbar-nav d-flex align-items-center justify-content-md-between col-12 mb-2 mt-3 m-md-0`}>
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} direction="down" className="dropdown-button d-flex align-items-center">
                                <DropdownToggle caret className='borderr '>
                                    {this.RenderLanguageLink(this.currentLanguage, true)}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {this.languagesImages.map((lang) => {
                                        return (
                                            <DropdownItem key={lang.name} onClick={() => this.ChangeLanguage(lang.code)}>
                                                {this.RenderLanguageLink(lang)}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }

    render() {

        if (this.props.isErrorPage)
            return this.RenderErrorPageNavBar();

        if (this.props.isAdm) {
            return this.RenderAdmNavbar()
        } else {
            return this.RenderPublicNavbar()
        }
  }
}
