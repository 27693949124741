import React, { Component } from 'react';
import { Button } from '@components/shared/Button';

export class FilterTranslation extends Component {
    static displayName = FilterTranslation.name;

    constructor(props) {
        super(props);

        this.state = {

        };

        this.translationStateOptions = [
            { text: 'Hotové', value: true},
            { text: 'Nekompletní', value: false },
            { text: 'Jakýkoliv', value: 'all' },
        ];
        this.isActiveOptions = [
            { text: 'Ano', value: true},
            { text: 'Ne', value: false },
            { text: 'Jakékoliv', value: 'all' },
        ];
        this.isCachedOption = [
            { text: 'Ne', value: true },
            { text: 'Ano', value: false },
            { text: 'Jakékoliv', value: 'all' },
        ];
        this.editableOptions = [
            { text: 'Ano', value: true },
            { text: 'Ne', value: false },
            { text: 'Jakékoliv', value: 'all' },
        ];
        this.paginationOptions = [
            { text: '1', value: 1},
            { text: '10', value: 10},
            { text: '25', value: 25},
            { text: '50', value: 50},
            { text: '100', value: 100},
            { text: 'Vše', value: 'all' },
        ];
        // translation page options are fetched from db
    }

    HandleDeleteFilters = () => {

        if (JSON.stringify(this.props.defaultFilters) == JSON.stringify(this.props.filters))
            return;

        this.props.HandleSetFilters(this.props.defaultFilters);
    }

    HandleFilterAllRecords = () => {
        let defaultFilters = JSON.parse(JSON.stringify(this.props.defaultFilters));
        defaultFilters.paginationCount = 'all';
        this.props.HandleSetFilters(defaultFilters);
    }

    HandleSetFilterIsComplete = (val) => {
        let newFilters = { ...this.props.filters, isComplete: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterIsActive = (val) => {
        let newFilters = { ...this.props.filters, isActive: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterKey = (val) => {
        let newFilters = { ...this.props.filters, key: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterLocalizationText = (val) => {
        let newFilters = { ...this.props.filters, localizationText: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterPage = (val) => {
        let newFilters = { ...this.props.filters, page: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetIsCached = (val) => {
        let newFilters = { ...this.props.filters, isCached: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetEditable = (val) => {
        let newFilters = { ...this.props.filters, editable: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterPaginationCount = (val) => {
        let newFilters = { ...this.props.filters, paginationCount: val, paginationPage: 1 }
        this.props.HandleSetFilters(newFilters);
    }

    HandleSetFilterPaginationPage = (val) => {
        let newFilters = { ...this.props.filters, paginationPage: val }
        this.props.HandleSetFilters(newFilters);
    }

    RenderPaginationPageNumbers = () => {

        let numberOfVisiblePages = 10;

        let pagination = this.props.filters.paginationCount == 'all' ? this.props.translationListCount : this.props.filters.paginationCount;
        let numberOfPages = Math.ceil(this.props.allRowsCount / pagination);
        if (numberOfPages <= 0)
            numberOfPages = 1;

        let pageNumbers = [];
        for (let i = 1; i <= numberOfPages; i++) {

            if (i > numberOfVisiblePages && i < numberOfPages - numberOfVisiblePages) {
                if(pageNumbers[pageNumbers.length-1] != '...')
                    pageNumbers.push('...');

                continue;
            }

            pageNumbers.push(i);
        }

        return (
            <div className='d-flex align-items-center flex-wrap'>
                {pageNumbers.map((pn) => {

                    if (pn == '...')
                        return <div key={pn} className='fw-bold'>{pn}</div>

                    return (
                        <div role='button' key={pn} className={`px-1 mx-1 pageNumber ${this.props.filters.paginationPage == pn ? 'current fw-bold' : ''}`} onClick={() => this.HandleSetFilterPaginationPage(pn) }>
                            {pn}
                        </div>
                    );
                })}
            </div>
        );     
    }

    RenderPaginationText = () => {

        let pagination = this.props.filters.paginationCount == 'all' ? this.props.translationListCount : this.props.filters.paginationCount;
        let paginationStart = (this.props.filters.paginationPage - 1) * pagination;
        return <div className=''>
            <div>
                {`Záznam ${paginationStart} - ${paginationStart + this.props.translationListCount} z celkových ${this.props.allRowsCount}`}
            </div>
            <div>
                Počet záznamů: {(paginationStart + this.props.translationListCount) - paginationStart}
            </div>
        </div>
    }

    RenderPageFilter = () => {

        let activePages = this.props.pageList.filter(p => p.isActive);
        return <>
            <label htmlFor='page'>Stránka</label>
            <select id='page' className='p-1' value={this.props.filters.page} onChange={(e) => this.HandleSetFilterPage(e.target.value)}>
                <option className='p-1' value={'all'}>
                    Jakákoliv
                </option>
                {activePages.map((p) => {
                    return (
                        <option className='p-1' value={p.id} key={p.id}>
                            {p.name}
                        </option>
                    );
                })}
            </select>
        </>
    }

    render() {
        return (
            <div className='d-flex flex-column'>
                <div className='border-bottom my-2 pb-2'>
                    <div className='d-flex flex-column flex-lg-row align-items-lg-end'>
                        <div className='d-flex flex-wrap'>
                            <div className='d-flex flex-wrap'>
                                <div className='mx-2 d-flex flex-column'>
                                    <label htmlFor='state'>Záznamů na stránce</label>
                                    <select id='state' className='p-1' value={this.props.filters.paginationCount} onChange={(e) => this.HandleSetFilterPaginationCount(e.target.value)}>
                                        {this.paginationOptions.map((o) => {
                                            return (
                                                <option className='p-1' value={o.value} key={o.value}>
                                                    {o.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className='mx-2 d-flex flex-column'>
                                    <label htmlFor='state'>Stav překladu</label>
                                    <select id='state' className='p-1' value={this.props.filters.isComplete} onChange={(e) => this.HandleSetFilterIsComplete(e.target.value)}>
                                        {this.translationStateOptions.map((o) => {
                                            return (
                                                <option className='p-1' value={o.value} key={o.value}>
                                                    {o.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className='mx-2 d-flex flex-column'>
                                    <label htmlFor='active'>Aktivní</label>
                                    <select id='active' className='p-1' value={this.props.filters.isActive} onChange={(e) => this.HandleSetFilterIsActive(e.target.value)}>
                                        {this.isActiveOptions.map((o) => {
                                            return (
                                                <option className='p-1' value={o.value} key={o.value}>
                                                    {o.text}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                {!this.props.forceCareer &&
                                    <>
                                        <div className='mx-2 d-flex flex-column'>
                                            <label htmlFor='active'>Překlad kariéry</label>
                                            <select id='active' className='p-1' value={this.props.filters.isCached} onChange={(e) => this.HandleSetIsCached(e.target.value)}>
                                                {this.isCachedOption.map((o) => {
                                                    return (
                                                        <option className='p-1' value={o.value} key={o.value}>
                                                            {o.text}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='mx-2 d-flex flex-column'>
                                            {this.RenderPageFilter()}
                                        </div>
                                        <div className='mx-2 d-flex flex-column'>
                                            <label htmlFor='page'>Upravitelné</label>
                                            <select id='page' className='p-1' value={this.props.filters.editable} onChange={(e) => this.HandleSetEditable(e.target.value)}>
                                                {this.editableOptions.map((e) => {
                                                    return (
                                                        <option className='p-1' value={e.value} key={e.value}>
                                                            {e.text}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </>
                                }
                            </div>                           
                            <div className='d-flex mt-2'>
                                <div className='mx-2 d-flex flex-column justify-content-end me-3'>
                                    <label htmlFor='key'>Vyhledat klíč</label>
                                    <input type='text' id='key' value={this.props.filters.key} onChange={(e) => this.HandleSetFilterKey(e.target.value)} />
                                </div>
                                <div className='mx-2 d-flex flex-column justify-content-end me-3'>
                                    <label htmlFor='localizationText'>Vyhledat text překladu</label>
                                    <input type='text' id='localizationText' value={this.props.filters.localizationText} onChange={(e) => this.HandleSetFilterLocalizationText(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className='ps-1 mt-2 mt-lg-0 d-flex justify-content-end flex-lg-column align-items-lg-end'>
                            <div className='me-2 me-lg-0 mb-2'>
                                <Button text={'Všechny záznamy'} OnClick={() => this.HandleFilterAllRecords()} />
                            </div>
                            <Button text={'Resetovat filtry'} OnClick={() => this.HandleDeleteFilters()} />
                        </div>
                    </div>
                </div>
                {this.RenderPaginationText()}
                <div className='pb-2 d-flex align-items-start'>
                    <span className='me-1'>Stránka:</span>
                    {this.RenderPaginationPageNumbers()}
                </div>
            </div>
        );
    }
}
