import React, { Component } from 'react';
import { AutomatedDispensaries } from './components/AutomatedDispensaries'; 
import { VendingMachines } from './components/VendingMachines';
import { SlideShow } from './components/SlideShow';
import { AboutUs } from './components/AboutUs';
import { Careers } from './components/Careers';
import { Contact } from './components/contact/Contact';
import '@styles/public/home/shared.scss';  
import { ReverseVendingMachines } from './components/ReverseVendingMachines';
import '@styles/public/home/home.scss';


export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {
        super(props);
        this.translate = this.props.translate;
    }

    componentDidMount() {
        document.title = "Sielaff Bohemia";
    }
   
    render() {
        return (
            <div id='home' className=''>
                {this.props.slideShow.length > 0 &&
                    <SlideShow link={this.props.navLinks.slideShow} translateRaw={this.props.translateRaw} translate={this.props.translate} slideShow={this.props.slideShow} />
                }
                <div className={`containerCustomMain`}>
                    <AutomatedDispensaries link={this.props.navLinks.ad} translateRaw={this.props.translateRaw} translate={this.props.translate} detailRefLink={this.props.navLinks.ad.subLinks.dispensariesDetail} />
                </div>
                <VendingMachines link={this.props.navLinks.vm} translateRaw={this.props.translateRaw} translate={this.props.translate} />
                <ReverseVendingMachines link={this.props.navLinks.rvm} translateRaw={this.props.translateRaw} translate={this.props.translate} />
                <div className='containerCustomMain'>
                    <AboutUs link={this.props.navLinks.aboutUs} translate={this.props.translate} />
                    <Careers link={this.props.navLinks.career} language={this.props.language} translateRaw={this.props.translateRaw} translate={this.props.translate} />
                    <Contact link={this.props.navLinks.contact} translate={this.props.translate} />
                </div>
            </div>
        );
    }
}
